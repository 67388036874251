'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: 'v2/active_session',

  defaults: {
    theme: null,
    subsequent_nut: null,
    completed: false,
    libra_id: null,
  }
});
