'use strict';

import LibraQuestionManager from './libra_questionmanager';
import NutQuestionManager from './nut_questionmanager';
import jQuery from 'jquery';
import I18n from './i18n';

let Validator = require('validatorjs');

export function getInstance (sessionId, isCompletedSession) {
  if (sessionId.match(/^([a-z_]+)$/)) {
    return new LibraQuestionManager(sessionId, isCompletedSession);
  }
  return new NutQuestionManager(sessionId, isCompletedSession);
}

export function getSubmittedAnswerValue (question, form) {
  let answer = {
    error: null,
    displayableValue: [],
    value: ''
  };
  let formData, rules, validation;

  switch (question.get('type')) {
    case 'message':
      answer.displayableValue.push(form.find('.form-submit').val());
      break;

    case 'multipleChoice':
      formData = {answer: form.find('[name="answer"]:checked').val()};
      if (question.isRequired()) {
        rules = {answer: 'required'};
        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: I18n.get('answer')});
        if (validation.passes()) {
          answer.value = formData.answer;
          answer.displayableValue.push(form.find('label[for="answer-' + answer.value + '"]').text());
        } else {
          answer.error = validation.errors.first('answer');
        }
      } else {
        answer.value = formData.answer;
        answer.displayableValue.push(form.find('label[for="answer-' + answer.value + '"]').text());
      }

      break;

    case 'number':
    case 'slider':
    case 'scale':
      formData = {answer: form.find('[name="answer"]').val()};
      rules = {answer: 'numeric'};

      if (question.isRequired()) {
        rules.answer += '|required';
      }
      if (question.get('min_value')) {
        rules.answer += '|min:' + question.get('min_value');
      }
      if (question.get('max_value')) {
        rules.answer += '|max:' + question.get('max_value');
      }

      validation = new Validator(formData, rules);
      validation.setAttributeNames({answer: I18n.get('answer')});
      if (validation.passes()) {
        answer.value = formData.answer;
      } else {
        answer.error = validation.errors.first('answer');
      }

      break;

    case 'text':
      formData = {answer: form.find('[name="answer"]').val()};
      rules = {answer: []};

      if (question.isRequired()) {
        rules.answer.push('required');
      }
      if (question.get('maxLength')) {
        rules.answer.push('max:' + question.get('maxLength'));
      }
      if (question.get('regex_pattern')) {
        rules.answer.push('regex:/' + question.get('regex_pattern') + '/');
      }

      if (rules.answer.length > 0) {
        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: I18n.get('answer')});
        if (validation.passes()) {
          answer.value = formData.answer;
        } else {
          answer.error = validation.errors.first('answer');
        }
      } else {
        answer.value = formData.answer;
      }

      break;

    case 'date':
      let inputDate = form.find('[name="answer"]').val();
      formData = {answer: transformDateFormatToApi(inputDate)};
      rules = {answer: []};

      if (question.isRequired()) {
        rules.answer.push('required');
      }

      if (rules.answer.length > 0) {
        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: I18n.get('answer')});
        if (validation.passes()) {
          answer.value = formData.answer;
          answer.displayableValue.push(inputDate);
        } else {
          answer.error = validation.errors.first('answer');
        }
      } else {
        answer.value = formData.answer;
        answer.displayableValue.push(inputDate);
      }

      break;

    case 'multiSelect':
      let answerIds = [];
      let answerDisplayableTexts = [];
      form.find('[name="answer"]:checked').each(function () {
        let answerId = jQuery(this).val();
        let answerText = form.find('label[for="answer-' + answerId + '"]').text();
        answerIds.push(answerId);
        answerDisplayableTexts.push(answerText);
      });
      formData = {answer: answerIds.join('|')};
      rules = {answer: []};

      if (question.isRequired()) {
        rules.answer.push('required');
      }
      if (question.get('min_value')) {
        rules.answer.push('min_selected:' + question.get('min_value'));
      }
      if (question.get('max_value')) {
        rules.answer.push('max_selected:' + question.get('max_value'));
      }

      if (rules.answer.length > 0) {
        validation = new Validator(formData, rules);
        validation.setAttributeNames({answer: I18n.get('answer')});
        if (validation.passes()) {
          answer.value = answerIds;
          answer.displayableValue = answerDisplayableTexts;
        } else {
          answer.error = validation.errors.first('answer');
        }
      } else {
        answer.value = answerIds;
        answer.displayableValue = answerDisplayableTexts;
      }

      break;
  }

  if (typeof answer.value === 'undefined') {
    answer.value = '';
  }

  if (answer.displayableValue.length === 0) {
    answer.displayableValue.push(answer.value);
  }

  return answer;
}

function transformDateFormatToApi(date) {
  if (date) {
    // We get the date in ISO-8601 format, convert to YYYYMMDD.
    let reg = date.match(/([0-9]{4})-([0-9]{2})-([0-9]{2})/);
    if (reg) {
      return reg[1] + reg[2] + reg[3];
    }
  }

  return null;
}