'use strict';

import ContentView from '../views/content';

import _ from 'underscore';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import BackMixin from '../utils/backmixin.js';
import I18n from '../utils/i18n';

import App from '../app';
import jQuery from 'jquery';

let LoginView = ContentView.extend({
  template: 'login',
  isPublic: true,

  ui: {
    username: '#username',
    password: '#password',
    submit: 'button[type="submit"]',
    back: '.back-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.back': 'back'
  },

  onRender: function (options) {
    App.refreshHeader({showBackButton: true});

    let username = '';
    let storageUsername = window.localStorage.getItem('username');
    if (storageUsername) {
      username = storageUsername;
    }

    this.ui.username.val(username);
  },

  submit: function (event) {
    event.preventDefault();

    let username = this.ui.username.val();
    let password = this.ui.password.val();

    if (!username || !password) {
      Dialogs.alert(I18n.get('enter_email_and_password'));
      return;
    }

    App.isBusy(true);
    Session.login(username, password)
      .then(
        function (loginData) {
          window.localStorage.setItem('username', username);

          if (window.FirebasePlugin) {
            window.FirebasePlugin.hasPermission(function (hasPermission) {
              if (!hasPermission) {
                window.FirebasePlugin.grantPermission();
              }
            });
          }

          App.clearCache();
          App.isBusy(false);
          if (I18n.getLanguage() !== loginData.preferred_language) {
            App.languageChanged = true;
          }
          if (loginData.preferred_language) {
            I18n.setPreferredLanguage(loginData.preferred_language);
          }
          App.router.navigate('#snippet', {trigger: true});
        },
        function (httpStatus, error) {
          if (httpStatus === 400) {
            Dialogs.alert(I18n.get('error_email_or_password_incorrect'));
          } else {
            Dialogs.alert(I18n.get('error_login_failed_check_connection'));
          }
          App.isBusy(false);
        }
      );
  }
});

_.extend(LoginView.prototype, BackMixin);

export default LoginView;
