'use strict';

import jQuery from 'jquery';

import App from '../app';

import UserModel from '../models/user';

import I18n from './i18n';

const API_URL = '@ENV.API_URL@';

const API_TIMEOUT = 30000;

class Session {
  constructor () {
    this._isLoggedIn = false;
    this._userId = null;
    this._sessionId = null;
    this._passwordRules = null;
    this._profile = null;

    // Retrieve settings.
    let settings = window.localStorage.getItem('session');
    if (settings) {
      this._settings = JSON.parse(settings);
    } else {
      this._settings = {};
    }
  }

  /**
   * Sets a settings item and updates local storage.
   *
   * @param string key
   * @param any    value
   */
  setItem (key, value) {
    this._settings[key] = value;
    window.localStorage.setItem('session', JSON.stringify(this._settings));
  }

  /**
   * Removes a settings item and updates local storage.
   *
   * @param  string key The key to remove.
   */
  deleteItem (key) {
    delete this._settings[key];
    window.localStorage.setItem('session', JSON.stringify(this._settings));
  }

  /**
   * Clears all session items
   */
  clearItems () {
    this._settings = {};
    window.localStorage.removeItem('session');
  }

  /**
   * Validates the session's credentials.
   *
   * @return Promise
   */
  validate () {
    let session = this;

    let promise = new Promise(function (resolve, reject) {
      if (!('userId' in session._settings) || !session._settings['userId']) {
        reject();
        return;
      }

      session._userId = session._settings['userId'];
      session._sessionId = session._settings['sessionId'];

      session.getProfile().then(
        function (data) {
          console.log('Session succesfully validated.');
          session._isLoggedIn = true;
          App.initPushNotifications();
          resolve(data);
        },
        function () {
          console.log('Session validation failed.');
          session._isLoggedIn = false;
          session._userId = null;
          session._sessionId = null;
          session._profile = null;
          session.clearItems();
          App.unregisterPushNotifications();
          reject();
        }
      );
    });

    return promise;
  }

  getProfile() {
    let session = this;

    return new Promise(function(resolve, reject) {
      if (session._profile) {
        console.log('Profile already present.');
        resolve(session._profile);
        return;
      }
      if (!session._userId) {
        reject();
      }

      let userModel = new UserModel({uid: session._userId});
      userModel.fetch()
        .done(function () {
          console.log('Successfully retrieved profile.');
          session._profile = userModel;
          resolve(userModel);
        })
        .fail(function () {
          console.log('Failed retrieving profile.');
          reject();
        });
    });
  }

  /**
   * Performs a user login. The session data is stored for reuse.
   *
   * @param  string username The username to log in with.
   * @param  string password The password to log in with.
   *
   * @return Promise
   */
  login (username, password) {
    let session = this;

    session._userId = null;
    session._sessionId = null;
    session._isLoggedIn = false;
    session._passwordRules = null;

    let promise = new Promise(function (resolve, reject) {
      if (!session.isConnected()) {
        reject();
        return;
      }

      jQuery.ajax({
        url: API_URL + 'v2/user/login?_format=json',
        method: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          name: username,
          pass: password
        }),
        timeout: API_TIMEOUT
      })
        .done(function (data, status, xhr) {
          if (!data.current_user) {
            reject();
            return;
          }

          session.setItem('userId', data.current_user.uid);
          session.setItem('sessionId', data.token);
          session.setItem('username', username);

          session._isLoggedIn = true;
          session._userId = data.current_user.uid;
          session._sessionId = data.token;

          App.initPushNotifications();
          resolve({
            username: username,
            user_id: data.current_user.uid,
            preferred_language: typeof data.current_user.preferred_language !== 'undefined' ? data.current_user.preferred_language : null,
          });
        })
        .fail(function (xhr, status, error) {
          reject(xhr.status, error);
        });
    });

    return promise;
  }

  /**
   * Logs the user out. All session related information is destroyed if the request succeeded.
   * Note that the returned promise will always be resolved and never rejected.
   *
   * @return Promise
   */
  logout () {
    let session = this;

    // Delete push notification token before logging out.
    App.unregisterPushNotifications();

    let promise = new Promise(function (resolve, reject) {
      if (!session.isConnected()) {
        reject();
        return;
      }

      session.request('POST', 'v2/user/logout')
        .done(function () {
          session._isLoggedIn = false;
          session._userId = null;
          session._sessionId = null;
          session._profile = null;

          session.clearItems();

          resolve();
        })
        .fail(function () {
          resolve();
        });
    });

    return promise;
  }

  /**
   * Sends an AJAX request with some default parameters.
   *
   * @param  string method   HTTP method.
   * @param  string endpoint URL endpoint name.
   * @param  any data        Optional data to send.
   *
   * @return jqXHR
   */
  request (method, endpoint, data, originalOptions) {
    let origin = window.cordova ? 'app' : 'site';
    if (App.kioskMode) {
      origin = 'demo';
    }
    let url = API_URL + endpoint;
    const queryParams = '_format=json&language=' + I18n.getLanguage();
    if (url.lastIndexOf('?') !== -1) {
      url += '&' + queryParams;
    } else {
      url += '?' + queryParams;
    }
    let options = {
      method: method,
      url: url,
      timeout: API_TIMEOUT,
      headers: {
        'X-API-Origin': origin
      }
    };
    options = jQuery.extend({}, originalOptions, options);

    if (method === 'POST' || method === 'PATCH') {
      options.contentType = 'application/json';
      if (data) {
        options.data = JSON.stringify(data);
      }
    } else if (data) {
      options.data = data;
    }
    if (this._sessionId) {
      options.headers['X-Session-ID'] = this._sessionId;
    }

    return jQuery.ajax(options).catch(function(error) {

      // If the request timed out, show a message and rethrow the error with status 200 so that the promise chain
      // will still stop but no further warnings will be handled.
      if (error.statusText == 'timeout') {
        Dialogs.alert(I18n.get('connection_msg'));
        App.isBusy(false);

        error.status = 200;
      }

      throw error;
    });
  }

  isConnected () {
    if (window.cordova) {
      return (navigator.connection.type !== Connection.NONE);
    } else {
      return true;
    }
  }

  /**
   * Returns true if the user is currently logged in.
   *
   * @return Boolean
   */
  get isLoggedIn () {
    return this._isLoggedIn;
  }

  get userId () {
    return this._userId;
  }

  /**
   * Returns the email address, undefined if it is not stored.
   *
   * @return string
   */
  get username () {
    return this._settings['username'];
  }

  get profile () {
    return this._profile;
  }
}

export default new Session();
