'use strict';

import ContentView from '../views/content';

import App from '../app';
import jQuery from 'jquery';

export default ContentView.extend({
  template: 'themes',
  isPublic: false,

  onAttach: function (options) {
    this.listenTo(this.collection, 'sync', this.update);
    this.collection.fetch({cache: true, expires: 60});
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'themes'});
  },

  update: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromCollection());
    this.render();
  },

  getTemplateVarsFromCollection: function () {
    let values = {
      sections: []
    };

    for (let i in App.config.get('themeCategories')) {
      let themes = [];
      this.collection.forEach(function (theme) {
        if (theme.get('category') === App.config.get('themeCategories')[i].id) {
          themes.push({
            id: theme.get('id'),
            title: theme.get('title'),
            icon: theme.get('icon'),
            isImprovement: theme.get('category') === App.SCORE_IMPROVE,
            isActive: theme.get('active')
          });
        }
      });

      if (themes.length > 0) {
        values.sections.push({
          title: App.config.get('themeCategories')[i].title,
          isImprovement: App.config.get('themeCategories')[i].id === App.SCORE_IMPROVE,
          isHoldOn: App.config.get('themeCategories')[i].id === App.SCORE_MAINTAIN,
          themes: themes,
        });
      }
    }

    return values;
  },

});
