'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  defaults: {
    id: 0,
    title: ''
  }
});
