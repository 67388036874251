import Handlebars from "handlebars"; export var chat_coach_question = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content bg-primary\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent cui col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <form class=\"formStyles\" action=\"#\" method=\"\" id=\"form-cui\">\n          <div class=\"chat-container\">\n            <div class=\"chat-log\"></div>\n          </div>\n        </form>\n      </article>\n    </div>\n  </div>\n</div>\n\n<div id=\"libra-confirm-close-modal\" class=\"modal\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <div class=\"modal-header\">\n        <button type=\"button\" class=\"close btn-close\" data-dismiss=\"modal\" aria-label=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"close",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":87},"end":{"line":19,"column":100}}}))
    + " modal\"><i class=\"icon-close_x\"></i></button>\n        <p class=\"modal-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"want_to_resume_later",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":31},"end":{"line":20,"column":59}}}))
    + "</p>\n      </div>\n      <div class=\"modal-body\">\n        <a href=\"#\" class=\"btn btn-secondary btn-resume-later\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"resume_later",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":63},"end":{"line":23,"column":83}}}))
    + "</a>\n        <a href=\"#\" class=\"btn btn-secondary btn-discard\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"no",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":24,"column":58},"end":{"line":24,"column":68}}}))
    + "</a>\n        <a href=\"#\" class=\"btn btn-close\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":25,"column":42},"end":{"line":25,"column":56}}}))
    + "</a>\n      </div>\n    </div><!-- /.modal-content -->\n  </div><!-- /.modal-dialog -->\n  <div id=\"libra-confirm-close-modal-backdrop\" class=\"modal-backdrop\"></div>\n</div>\n";
},"useData":true})
export var chat_question_answer = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + container.escapeExpression(container.lambda(depth0, depth0))
    + ((stack1 = lookupProperty(helpers,"unless").call(depth0 != null ? depth0 : (container.nullContext || {}),(data && lookupProperty(data,"last")),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":12},"end":{"line":3,"column":44}}})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "<br>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<p>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"answers") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":4,"column":11}}})) != null ? stack1 : "")
    + "</p>";
},"useData":true})
export var chat_question_input = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <button class=\"btn btn-secondary btn-cui form-submit\" role=\"button\" type=\"submit\" name=\"op\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"chatui_ok",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":101},"end":{"line":2,"column":119}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"chatui_ok",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":2,"column":121},"end":{"line":2,"column":139}}}))
    + "</button>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"chatui_navigation"),depth0,{"name":"chatui_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_multiple_choice"),depth0,{"name":"question_multiple_choice","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(lookupProperty(partials,"chatui_navigation"),depth0,{"name":"chatui_navigation","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_number"),depth0,{"name":"question_number","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_slider"),depth0,{"name":"question_slider","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_text"),depth0,{"name":"question_text","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_date"),depth0,{"name":"question_date","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"question_multiselect"),depth0,{"name":"question_multiselect","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"message",{"name":"ifeq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":4,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multipleChoice",{"name":"ifeq","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"number",{"name":"ifeq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":0},"end":{"line":13,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"slider",{"name":"ifeq","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":0},"end":{"line":17,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"scale",{"name":"ifeq","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":0},"end":{"line":21,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"text",{"name":"ifeq","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":23,"column":0},"end":{"line":25,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"date",{"name":"ifeq","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":0},"end":{"line":29,"column":9}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiSelect",{"name":"ifeq","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":31,"column":0},"end":{"line":33,"column":9}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true})
export var chat_question_message = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":3,"column":14}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question") || (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"question","hash":{},"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":5,"column":18}}}) : helper))) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":4},"end":{"line":8,"column":11}}})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      <p class=\"description\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":7,"column":29},"end":{"line":7,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"message\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"text") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":9,"column":9}}})) != null ? stack1 : "")
    + "</div>";
},"useData":true})
export var chat_question_submit = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<button class=\"btn btn-secondary btn-cui form-submit\" role=\"button\" type=\"submit\" name=\"op\" value=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"chatui_continue",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":99},"end":{"line":1,"column":123}}}))
    + "\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"chatui_continue",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":1,"column":125},"end":{"line":1,"column":149}}}))
    + "</button>\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"chatui_navigation"),depth0,{"name":"chatui_navigation","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true})
export var chatui_message = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda(depth0, depth0))
    + " ";
},"3":function(container,depth0,helpers,partials,data) {
    return "      <span class=\"chat-loading\">\n        <span></span>\n        <span></span>\n        <span></span>\n      </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "      "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":10,"column":6},"end":{"line":10,"column":19}}}) : helper))) != null ? stack1 : "")
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"chat-message "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"classes") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":25},"end":{"line":1,"column":60}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"chat-message__inner\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loading") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":3,"column":4},"end":{"line":11,"column":11}}})) != null ? stack1 : "")
    + "  </div>\n</div>\n";
},"useData":true})
export var coach_question = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <header class=\"no-padding\" id=\"header\" role=\"banner\">\n    <div class=\"progress\">\n      <div class=\"progress-bar\" role=\"progressbar\" aria-valuenow=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"progess") || (depth0 != null ? lookupProperty(depth0,"progess") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"progess","hash":{},"data":data,"loc":{"start":{"line":4,"column":66},"end":{"line":4,"column":77}}}) : helper)))
    + "\" aria-valuemin=\"0\" aria-valuemax=\"100\" style=\"width: "
    + alias4(((helper = (helper = lookupProperty(helpers,"progress") || (depth0 != null ? lookupProperty(depth0,"progress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"progress","hash":{},"data":data,"loc":{"start":{"line":4,"column":131},"end":{"line":4,"column":143}}}) : helper)))
    + "%;\">\n        <span class=\"sr-only\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"progress") || (depth0 != null ? lookupProperty(depth0,"progress") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"progress","hash":{},"data":data,"loc":{"start":{"line":5,"column":30},"end":{"line":5,"column":42}}}) : helper)))
    + "% "
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"completed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":44},"end":{"line":5,"column":62}}}))
    + "</span>\n      </div>\n    </div>\n  </header>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " bg-primary";
},"5":function(container,depth0,helpers,partials,data) {
    return "libra-full";
},"7":function(container,depth0,helpers,partials,data) {
    return "libra-short";
},"9":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h4 class=\"libra-progress\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"theme_title") || (depth0 != null ? lookupProperty(depth0,"theme_title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"theme_title","hash":{},"data":data,"loc":{"start":{"line":18,"column":41},"end":{"line":18,"column":56}}}) : helper)))
    + "</h4>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h4 class=\"libra-progress\">"
    + alias3(((helper = (helper = lookupProperty(helpers,"progress") || (depth0 != null ? lookupProperty(depth0,"progress") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"progress","hash":{},"data":data,"loc":{"start":{"line":20,"column":41},"end":{"line":20,"column":53}}}) : helper)))
    + "% "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"completed",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":55},"end":{"line":20,"column":73}}}))
    + "</h4>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <h2 class=\"title\">"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"question") || (depth0 != null ? lookupProperty(depth0,"question") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"question","hash":{},"data":data,"loc":{"start":{"line":23,"column":32},"end":{"line":23,"column":46}}}) : helper))) != null ? stack1 : "")
    + "</h2>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"description") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":24,"column":14},"end":{"line":26,"column":21}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <p>"
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"description","hash":{},"data":data,"loc":{"start":{"line":25,"column":19},"end":{"line":25,"column":36}}}) : helper))) != null ? stack1 : "")
    + "</p>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"message\">\n              "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"text") || (depth0 != null ? lookupProperty(depth0,"text") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"text","hash":{},"data":data,"loc":{"start":{"line":32,"column":14},"end":{"line":32,"column":24}}}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multipleChoice",{"name":"ifeq","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":41,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"number",{"name":"ifeq","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":43,"column":12},"end":{"line":47,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"slider",{"name":"ifeq","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":12},"end":{"line":53,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"scale",{"name":"ifeq","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":12},"end":{"line":59,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"text",{"name":"ifeq","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":12},"end":{"line":65,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"date",{"name":"ifeq","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":12},"end":{"line":71,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"multiSelect",{"name":"ifeq","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":73,"column":12},"end":{"line":77,"column":21}}})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_multiple_choice"),depth0,{"name":"question_multiple_choice","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_number"),depth0,{"name":"question_number","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_slider"),depth0,{"name":"question_slider","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_text"),depth0,{"name":"question_text","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_date"),depth0,{"name":"question_date","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"answers\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"question_multiselect"),depth0,{"name":"question_multiselect","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"givenAnswer") : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":12},"end":{"line":88,"column":19}}})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"given-answer\">\n                <p>\n                  Jouw antwoord was:<br>\n                  <strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"givenAnswer") || (depth0 != null ? lookupProperty(depth0,"givenAnswer") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"givenAnswer","hash":{},"data":data,"loc":{"start":{"line":85,"column":26},"end":{"line":85,"column":41}}}) : helper)))
    + "</strong>\n                </p>\n              </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"cancel\">\n              <a href=\"#\"><span>x</span> "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"stop_test",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":41},"end":{"line":98,"column":58}}}))
    + "</a>\n            </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":11}}})) != null ? stack1 : "")
    + "\n<div class=\"page-content"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":24},"end":{"line":11,"column":66}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent libra "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":14,"column":40},"end":{"line":14,"column":92}}})) != null ? stack1 : "")
    + " col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <form class=\"formStyles\" action=\"\" method=\"\" id=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":15,"column":57},"end":{"line":15,"column":109}}})) != null ? stack1 : "")
    + "\">\n          <div class=\"question\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isDailySession") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":17,"column":12},"end":{"line":21,"column":19}}})) != null ? stack1 : "")
    + ((stack1 = (lookupProperty(helpers,"ifne")||(depth0 && lookupProperty(depth0,"ifne"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"message",{"name":"ifne","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":12},"end":{"line":27,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n\n"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"message",{"name":"ifeq","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":10},"end":{"line":34,"column":19}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"givenAnswer") : depth0),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":36,"column":10},"end":{"line":78,"column":21}}})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (lookupProperty(helpers,"ifne")||(depth0 && lookupProperty(depth0,"ifne"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"type") : depth0),"message",{"name":"ifne","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":80,"column":10},"end":{"line":89,"column":19}}})) != null ? stack1 : "")
    + "\n          <div class=\"controls\">\n            <button class=\"btn btn-transparent btn-next form-submit\" role=\"button\" type=\"submit\" name=\"op\" value=\"Volgende\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"next",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":92,"column":124},"end":{"line":92,"column":137}}}))
    + "<i class=\"icon-chevron-right\"></i></button>\n            <button class=\"btn btn-transparent btn-prev form-submit\" role=\"button\" type=\"submit\" name=\"op\" value=\"Vorige\"><i class=\"icon-chevron-left\"></i>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"previous",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":93,"column":155},"end":{"line":93,"column":172}}}))
    + "</button>\n          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"unless","hash":{},"fn":container.program(34, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":96,"column":10},"end":{"line":100,"column":21}}})) != null ? stack1 : "")
    + "        </form>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true})
export var forgot_password = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent password col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <h3 class=\"page-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"change_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":54}}}))
    + "</h3>\n        <form action=\"\" class=\"formStyles user-form\" role=\"form\">\n          <div class=\"form-item form-type-textfield\">\n            <label for=\"username\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":56},"end":{"line":8,"column":69}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"username\" name=\"username\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":98}}}))
    + "\" type=\"text\">\n          </div>\n\n          <div class=\"form-actions\">\n            <button class=\"btn btn-secondary\" type=\"submit\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"request_new_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":60},"end":{"line":13,"column":88}}}))
    + "</button>\n            <a class=\"cancel back-link\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"back",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":49},"end":{"line":14,"column":61}}}))
    + "</a>\n          </div>\n        </form>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var header = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"loggedIn") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":2,"column":2},"end":{"line":65,"column":9}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <header id=\"header\" class=\"no-padding\" role=\"banner\">\n      <nav class=\"main-nav\" role=\"navigation\">\n        <ul class=\"menu\">\n          <li class=\"menu-item\">\n            <a href=\"#snippet\" class=\"tab-button\" data-subpage=\"snippet\">\n              <img src=\"images/menu-noot.png\" alt=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bust_nut",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":51},"end":{"line":8,"column":67}}}))
    + "\" width=\"53\" height=\"37\">\n              <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"nut_of_the_day",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":20},"end":{"line":9,"column":42}}}))
    + "</span>\n            </a>\n          </li>\n          <li class=\"menu-item\">\n            <a href=\"#themes\" class=\"tab-button\" data-subpage=\"themes\">\n              <i class=\"icon-themas\"></i>\n              <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"themes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":20},"end":{"line":15,"column":34}}}))
    + "</span>\n            </a>\n          </li>\n          <li class=\"menu-item\">\n            <a href=\"#info\" class=\"tab-button\" data-subpage=\"info\">\n              <i class=\"icon-info\"></i>\n              <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"information",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":20},"end":{"line":21,"column":39}}}))
    + "</span>\n            </a>\n          </li>\n          <li class=\"menu-item\">\n            <a href=\"#profile\" class=\"tab-button\" data-subpage=\"profile\">\n              <i class=\"icon-profiel\"></i>\n              <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":20},"end":{"line":27,"column":35}}}))
    + "</span>\n            </a>\n          </li>\n        </ul>\n      </nav>\n    </header>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <header id=\"header\" role=\"banner\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <div class=\"col-xs-12\">\n            <div class=\"actions-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showBackButton") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":39,"column":14},"end":{"line":43,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"actions-wrapper\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showLoginButton") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":14},"end":{"line":48,"column":21}}})) != null ? stack1 : "")
    + "              <div class=\"dropdown\">\n                <button class=\"btn dropdown-toggle\" type=\"button\" id=\"dropdown-language\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                  <span class=\"title\"></span>\n                  <i class=\"icon-chevron-down\"></i>\n                </button>\n                <ul class=\"dropdown-menu dropdown-menu-right\" aria-labelledby=\"dropdown-language\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"languages") : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":55,"column":18},"end":{"line":57,"column":27}}})) != null ? stack1 : "")
    + "                </ul>\n              </div>\n            </div>\n          </div>\n        </div>\n      </div>\n    </header>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"btn btn-quinary btn-icon btn-icon-white btn-header-back back-link\" href=\"#\"><i class=\"icon-chevron-left\"></i><span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"back",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":40,"column":141},"end":{"line":40,"column":153}}}))
    + "</span></a>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"btn btn-transparent btn-icon\" href=\"#info\"><i class=\"icon-info\"></i><span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"information",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":100},"end":{"line":42,"column":119}}}))
    + "</span></a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <a class=\"btn btn-quinary\" href=\"#login\"><span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":63},"end":{"line":47,"column":76}}}))
    + "</span></a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var helper, alias1=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    <li><a href=\"#\" data-language=\""
    + alias1(((helper = (helper = lookupProperty(helpers,"key") || (data && lookupProperty(data,"key"))) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"key","hash":{},"data":data,"loc":{"start":{"line":56,"column":51},"end":{"line":56,"column":59}}}) : helper)))
    + "\">"
    + alias1(container.lambda(depth0, depth0))
    + "</a></li>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "  <header class=\"no-padding\" id=\"header\" role=\"banner\"></header>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"showHeader") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(13, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":68,"column":7}}})) != null ? stack1 : "");
},"useData":true})
export var home = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"content","hash":{},"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":1,"column":13}}}) : helper))) != null ? stack1 : "");
},"useData":true})
export var info = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <li><span data-id=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":12,"column":33},"end":{"line":12,"column":39}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":12,"column":41},"end":{"line":12,"column":50}}}) : helper)))
    + "</span></li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent single col-xs-12 col-sm-10 col-sm-offset-1\">\n        <div class=\"dropdown\">\n          <button class=\"btn dropdown-toggle\" type=\"button\" id=\"dropdownMenu1\" data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"true\">\n            <span class=\"title\"></span>\n            <i class=\"icon-chevron-down\"></i>\n          </button>\n          <ul class=\"dropdown-menu\" aria-labelledby=\"dropdownMenu1\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"items") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "          </ul>\n        </div>\n        <hr>\n        <div class=\"contentContainer\"></div>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var info_content = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<header class=\"articleHead\">\n  <h1 class=\"title\">"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":2,"column":20},"end":{"line":2,"column":29}}}) : helper)))
    + "</h1>\n</header>\n\n<div class=\"articleContent\">\n  "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"content") || (depth0 != null ? lookupProperty(depth0,"content") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"content","hash":{},"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":15}}}) : helper))) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true})
export var login = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent login col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <h3 class=\"page-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":44}}}))
    + "</h3>\n        <form action=\"\" class=\"formStyles user-form\" role=\"form\">\n          <div class=\"form-item form-type-textfield\">\n            <label for=\"username\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":56},"end":{"line":8,"column":69}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"username\" name=\"username\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":85},"end":{"line":9,"column":98}}}))
    + "\" type=\"email\">\n          </div>\n\n          <div class=\"form-item form-type-password\">\n            <label for=\"password\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":56},"end":{"line":13,"column":72}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"password\" name=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":14,"column":85},"end":{"line":14,"column":101}}}))
    + "\" type=\"password\">\n          </div>\n\n          <div class=\"form-actions\">\n            <button class=\"btn btn-secondary\" type=\"submit\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"login",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":60},"end":{"line":18,"column":73}}}))
    + "</button>\n            <a class=\"cancel\" href=\"#forgot-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"forgot_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":19,"column":54},"end":{"line":19,"column":77}}}))
    + "</a><br>\n            <a class=\"cancel\" href=\"#reset-password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"reset_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":53},"end":{"line":20,"column":75}}}))
    + "</a><br>\n            <a class=\"cancel back-link\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"back",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":49},"end":{"line":21,"column":61}}}))
    + "</a>\n          </div>\n        </form>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var profile = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <h3 class=\"page-title\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"profile",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":6,"column":33},"end":{"line":6,"column":48}}}))
    + "</h3>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <h3 class=\"page-title\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"join",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":33},"end":{"line":8,"column":45}}}))
    + "</h3>\n          <p>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"make_free_account",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":9,"column":13},"end":{"line":9,"column":40}}})) != null ? stack1 : "")
    + "</p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " checked=\"checked\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"form-item form-type-radio\">\n                <input type=\"radio\" id=\"lang"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":39,"column":44},"end":{"line":39,"column":53}}}) : helper)))
    + "\" name=\"language\" value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":39,"column":78},"end":{"line":39,"column":87}}}) : helper)))
    + "\" class=\"form-radio\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":39,"column":107},"end":{"line":39,"column":148}}})) != null ? stack1 : "")
    + ">\n                <label class=\"option\" for=\"lang"
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":40,"column":47},"end":{"line":40,"column":56}}}) : helper)))
    + "\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":40,"column":58},"end":{"line":40,"column":67}}}) : helper)))
    + "</label>\n              </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <option value=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"value") || (depth0 != null ? lookupProperty(depth0,"value") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"value","hash":{},"data":data,"loc":{"start":{"line":61,"column":31},"end":{"line":61,"column":40}}}) : helper)))
    + "\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"selected") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":41},"end":{"line":61,"column":84}}})) != null ? stack1 : "")
    + ">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":61,"column":85},"end":{"line":61,"column":94}}}) : helper)))
    + "</option>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " selected=\"selected\"";
},"12":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-item form-type-password\">\n              <label for=\"password\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"current_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":68,"column":36},"end":{"line":68,"column":60}}}))
    + "</label>\n              <input class=\"form-type-text\" id=\"existing_password\" name=\"existing_password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"current_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":69,"column":105},"end":{"line":69,"column":129}}}))
    + "\" type=\"password\">\n            </div>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return " class=\"form-required\"";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"form-item form-checkboxes\">\n              <input class=\"form-type-checkbox\" id=\"privacy_policy_approved\" name=\"privacy_policy_approved\" type=\"checkbox\">\n              <label for=\"privacy_policy_approved\">\n                <strong>\n                  "
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"privacy_consent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":18},"end":{"line":90,"column":43}}})) != null ? stack1 : "")
    + "<a href=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"privacyStatementUrl") || (depth0 != null ? lookupProperty(depth0,"privacyStatementUrl") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"privacyStatementUrl","hash":{},"data":data,"loc":{"start":{"line":90,"column":52},"end":{"line":90,"column":75}}}) : helper)))
    + "\" target=\"_blank\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"read_privacy_statement",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":93},"end":{"line":90,"column":123}}}))
    + "</a>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"privacy_consent_explanation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":90,"column":127},"end":{"line":90,"column":164}}})) != null ? stack1 : "")
    + "\n                </strong>\n              </label>\n            </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <button class=\"btn btn-secondary\" type=\"submit\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"save",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":108,"column":62},"end":{"line":108,"column":74}}}))
    + "</button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <button class=\"btn btn-secondary\" type=\"submit\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"start_app",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":110,"column":62},"end":{"line":110,"column":79}}}))
    + "</button>\n              <a class=\"cancel\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"cancel",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":111,"column":41},"end":{"line":111,"column":55}}}))
    + "</a>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p><a href=\"#logout\">"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"logout",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":116,"column":33},"end":{"line":116,"column":47}}}))
    + "</a></p>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent register col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":5,"column":8},"end":{"line":10,"column":15}}})) != null ? stack1 : "")
    + "\n        <form action=\"\" class=\"formStyles user-form\" role=\"form\">\n\n          <div class=\"form-item form-type-textfield\">\n            <label for=\"email\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":15,"column":53},"end":{"line":15,"column":66}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"email\" name=\"email\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":79},"end":{"line":16,"column":92}}}))
    + "\" type=\"email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"email") || (depth0 != null ? lookupProperty(depth0,"email") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"email","hash":{},"data":data,"loc":{"start":{"line":16,"column":114},"end":{"line":16,"column":123}}}) : helper)))
    + "\" required>\n          </div>\n\n          <div class=\"form-item form-radios form-radios-multiple\">\n            <label for=\"gender\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"gender",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":20,"column":54},"end":{"line":20,"column":68}}}))
    + "</label>\n            <div class=\"form-item form-type-radio\">\n              <input type=\"radio\" id=\"gender1\" name=\"gender\" value=\"male\" class=\"form-radio\""
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gender") : depth0),"male",{"name":"ifeq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":92},"end":{"line":22,"column":142}}})) != null ? stack1 : "")
    + ">\n              <label class=\"option\" for=\"gender1\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"male",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":50},"end":{"line":23,"column":62}}}))
    + "</label>\n            </div>\n            <div class=\"form-item form-type-radio\">\n              <input type=\"radio\" id=\"gender2\" name=\"gender\" value=\"female\" class=\"form-radio\""
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gender") : depth0),"female",{"name":"ifeq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":94},"end":{"line":26,"column":146}}})) != null ? stack1 : "")
    + ">\n              <label class=\"option\" for=\"gender2\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"female",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":50},"end":{"line":27,"column":64}}}))
    + "</label>\n            </div>\n            <div class=\"form-item form-type-radio\">\n              <input type=\"radio\" id=\"gender3\" name=\"gender\" value=\"other\" class=\"form-radio\""
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"gender") : depth0),"other",{"name":"ifeq","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":93},"end":{"line":30,"column":144}}})) != null ? stack1 : "")
    + ">\n              <label class=\"option\" for=\"gender3\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"other",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":31,"column":50},"end":{"line":31,"column":63}}}))
    + "</label>\n            </div>\n          </div>\n\n          <div class=\"form-item form-radios form-radios-multiple\">\n            <label class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"language",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":36,"column":41},"end":{"line":36,"column":57}}}))
    + "</label>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"languages") : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":12},"end":{"line":42,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n\n          <div class=\"form-item form-type-textfield\">\n            <label for=\"birthdate\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"birth_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":46,"column":57},"end":{"line":46,"column":75}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"birthdate\" name=\"birthdate\"\n              placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"birth_date",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":48,"column":27},"end":{"line":48,"column":45}}}))
    + "\" type=\"text\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"birthdate") || (depth0 != null ? lookupProperty(depth0,"birthdate") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"birthdate","hash":{},"data":data,"loc":{"start":{"line":48,"column":66},"end":{"line":48,"column":79}}}) : helper)))
    + "\" required>\n          </div>\n\n          <div class=\"form-item form-checkboxes\">\n            <input class=\"form-type-checkbox\" id=\"pdp_participant\" name=\"pdp_participant\" type=\"checkbox\" value=\"1\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"pdp_participant") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":52,"column":115},"end":{"line":52,"column":163}}})) != null ? stack1 : "")
    + ">\n            <label for=\"pdp_participant\"><strong>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"i_am_pdp_participant",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":53,"column":49},"end":{"line":53,"column":79}}})) != null ? stack1 : "")
    + "</strong></label>\n          </div>\n\n          <div class=\"form-item form-type-select\">\n            <label for=\"region\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"region",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":57,"column":32},"end":{"line":57,"column":46}}}))
    + "</label>\n            <select name=\"region\">\n              <option value=\"\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"choose_one",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":31},"end":{"line":59,"column":49}}}))
    + "</option>\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"regions") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":60,"column":14},"end":{"line":62,"column":23}}})) != null ? stack1 : "")
    + "            </select>\n          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":66,"column":10},"end":{"line":71,"column":17}}})) != null ? stack1 : "")
    + "\n          <div class=\"form-item form-type-password\">\n            <label for=\"password\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":74,"column":33},"end":{"line":74,"column":82}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":74,"column":83},"end":{"line":74,"column":99}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"password\" name=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":75,"column":85},"end":{"line":75,"column":101}}}))
    + "\" type=\"password\">\n          </div>\n\n          <div class=\"alert alert-info\" role=\"alert\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password_requirements_hint",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":53},"end":{"line":78,"column":87}}}))
    + "</div>\n\n          <div class=\"form-item form-type-password\">\n            <label for=\"password_confirmation\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":81,"column":46},"end":{"line":81,"column":95}}})) != null ? stack1 : "")
    + ">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password_confirmation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":81,"column":96},"end":{"line":81,"column":125}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"password_confirmation\" name=\"password_confirmation\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password_confirmation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":82,"column":111},"end":{"line":82,"column":140}}}))
    + "\" type=\"password\">\n          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"unless","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":85,"column":10},"end":{"line":94,"column":21}}})) != null ? stack1 : "")
    + "\n          <div class=\"form-item form-checkboxes\">\n            <input class=\"form-type-checkbox\" id=\"contact_activities_events\" name=\"contact_activities_events\" type=\"checkbox\" value=\"1\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contact_activities_events") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":97,"column":135},"end":{"line":97,"column":193}}})) != null ? stack1 : "")
    + ">\n            <label for=\"contact_activities_events\"><strong>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"contact_activities_events_consent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":98,"column":59},"end":{"line":98,"column":102}}})) != null ? stack1 : "")
    + "</strong></label>\n          </div>\n\n          <div class=\"form-item form-checkboxes\">\n            <input class=\"form-type-checkbox\" id=\"contact_studies\" name=\"contact_studies\" type=\"checkbox\" value=\"1\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"contact_studies") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":102,"column":115},"end":{"line":102,"column":163}}})) != null ? stack1 : "")
    + ">\n            <label for=\"contact_studies\"><strong>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"contact_studies_consent",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":103,"column":49},"end":{"line":103,"column":80}}}))
    + "</strong></label>\n          </div>\n\n          <div class=\"form-actions\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":107,"column":12},"end":{"line":112,"column":19}}})) != null ? stack1 : "")
    + "          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"edit") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":115,"column":10},"end":{"line":117,"column":17}}})) != null ? stack1 : "")
    + "        </form>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var reset_password = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, alias4="function", lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent password col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <h3 class=\"page-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"reset_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":53}}}))
    + "</h3>\n\n        <div class=\"alert alert-warning\" role=\"alert\">\n          "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"reset_password_instructions",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":10},"end":{"line":8,"column":45}}}))
    + "\n        </div>\n\n        <div class=\"alert alert-info\" role=\"alert\">\n          "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password_requirements_hint",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":12,"column":10},"end":{"line":12,"column":44}}}))
    + "\n        </div>\n\n        <form action=\"\" class=\"formStyles user-form\" role=\"form\">\n          <div class=\"form-item form-type-textfield\">\n            <label for=\"username\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":56},"end":{"line":17,"column":69}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"mail\" name=\"mail\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"email",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":18,"column":77},"end":{"line":18,"column":90}}}))
    + "\" type=\"email\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"mail") || (depth0 != null ? lookupProperty(depth0,"mail") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mail","hash":{},"data":data,"loc":{"start":{"line":18,"column":112},"end":{"line":18,"column":120}}}) : helper)))
    + "\">\n          </div>\n\n          <div class=\"form-item form-type-password\">\n            <label for=\"old_password\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"temp_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":60},"end":{"line":22,"column":81}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"temp_password\" name=\"temp_password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"temp_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":95},"end":{"line":23,"column":116}}}))
    + "\" type=\"password\" value=\""
    + alias3(((helper = (helper = lookupProperty(helpers,"tempPassword") || (depth0 != null ? lookupProperty(depth0,"tempPassword") : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"tempPassword","hash":{},"data":data,"loc":{"start":{"line":23,"column":141},"end":{"line":23,"column":157}}}) : helper)))
    + "\">\n          </div>\n\n          <div class=\"form-item form-type-password\">\n            <label for=\"password\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"new_password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":27,"column":56},"end":{"line":27,"column":76}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"password\" name=\"password\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"password",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":28,"column":85},"end":{"line":28,"column":101}}}))
    + "\" type=\"password\">\n          </div>\n\n          <div class=\"form-item form-type-password\">\n            <label for=\"password_confirmation\" class=\"form-required\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"new_password_confirmation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":69},"end":{"line":32,"column":102}}}))
    + "</label>\n            <input class=\"form-type-text\" id=\"password_confirmation\" name=\"password_confirmation\" placeholder=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"new_password_confirmation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":33,"column":111},"end":{"line":33,"column":144}}}))
    + "\" type=\"password\">\n          </div>\n\n          <div class=\"form-actions\">\n            <button class=\"btn btn-secondary\" type=\"submit\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"change",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":60},"end":{"line":37,"column":74}}}))
    + "</button>\n            <a class=\"cancel back-link\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"back",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":38,"column":49},"end":{"line":38,"column":61}}}))
    + "</a>\n          </div>\n\n        </form>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var root = Handlebars.template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    return "<div class=\"region-header\"></div>\n\n<main id=\"content\" class=\"region-content\" role=\"main\"></main>\n\n<div id=\"busy\">\n  <div class=\"loading-container\">\n    <div class=\"loading\"><i class=\"icon-spin4 animate-spin\"></i></div>\n  </div>\n</div>\n\n<div id=\"offline\">\n  <div class=\"loading-container\">\n    <div class=\"message\"></div>\n  </div>\n</div>\n";
},"useData":true})
export var short_libra_score = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div title=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"themeTitle") || (depth0 != null ? lookupProperty(depth0,"themeTitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeTitle","hash":{},"data":data,"loc":{"start":{"line":9,"column":26},"end":{"line":9,"column":40}}}) : helper)))
    + "\" class=\"theme theme-"
    + alias4(((helper = (helper = lookupProperty(helpers,"themeNr") || (depth0 != null ? lookupProperty(depth0,"themeNr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeNr","hash":{},"data":data,"loc":{"start":{"line":9,"column":61},"end":{"line":9,"column":72}}}) : helper)))
    + " "
    + alias4(((helper = (helper = lookupProperty(helpers,"themeClass") || (depth0 != null ? lookupProperty(depth0,"themeClass") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":73},"end":{"line":9,"column":87}}}) : helper)))
    + " "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImprovement") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":88},"end":{"line":9,"column":135}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isMaintain") : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":135},"end":{"line":9,"column":170}}})) != null ? stack1 : "")
    + "\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImprovement") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":10,"column":16},"end":{"line":10,"column":88}}})) != null ? stack1 : "")
    + "\n                <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"themeIcon") || (depth0 != null ? lookupProperty(depth0,"themeIcon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"themeIcon","hash":{},"data":data,"loc":{"start":{"line":11,"column":26},"end":{"line":11,"column":39}}}) : helper)))
    + "\"></i>\n              </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "is-room-improvement";
},"4":function(container,depth0,helpers,partials,data) {
    return "is-hold-on";
},"6":function(container,depth0,helpers,partials,data) {
    return "<span class=\"improvement-indicator\">!</span>";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p class=\"is-room-improvement\">"
    + ((stack1 = (lookupProperty(helpers,"ifeq")||(depth0 && lookupProperty(depth0,"ifeq"))||alias2).call(alias1,(depth0 != null ? lookupProperty(depth0,"improvements") : depth0),1,{"name":"ifeq","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data,"loc":{"start":{"line":16,"column":43},"end":{"line":16,"column":154}}})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"improvements") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":14},"end":{"line":17,"column":139}}})) != null ? stack1 : "")
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"test_result_improve_after",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":139},"end":{"line":17,"column":172}}}))
    + ".\n            </p>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasMaintain") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":19,"column":12},"end":{"line":24,"column":19}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"test_result_improve_singular",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":67},"end":{"line":16,"column":103}}}));
},"11":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"test_result_improve_plural",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":111},"end":{"line":16,"column":145}}}));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<strong>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":17,"column":44},"end":{"line":17,"column":53}}}) : helper)))
    + "</strong>"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isSecondToLast") : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":62},"end":{"line":17,"column":104}}})) != null ? stack1 : "")
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isNotLast") : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":104},"end":{"line":17,"column":130}}})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return " "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"and",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":17,"column":85},"end":{"line":17,"column":96}}}))
    + " ";
},"16":function(container,depth0,helpers,partials,data) {
    return ", ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class=\"is-hold-on\">\n                "
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"test_result_maintain",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":21,"column":16},"end":{"line":21,"column":44}}}))
    + "\n                "
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"maintain") : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":16},"end":{"line":22,"column":137}}})) != null ? stack1 : "")
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"test_result_keep_up",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":22,"column":137},"end":{"line":22,"column":164}}}))
    + "\n              </p>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"test_result_good",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":26,"column":15},"end":{"line":26,"column":39}}}))
    + "</p>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"thanks_result_page",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":37,"column":15},"end":{"line":37,"column":41}}}))
    + "</p>\n          <!--  <div class=\"qr-code\">\n              <img src=\"images/qr-code.svg\" alt=\"\" width=\"220\" height=\"220\">\n            </div> -->\n";
},"24":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"register_for_more",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":42,"column":15},"end":{"line":42,"column":40}}}))
    + "</p>\n            <a class=\"btn btn-secondary register\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"i_will_participate",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":43,"column":59},"end":{"line":43,"column":85}}}))
    + "</a>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content bg-primary\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent libra libra-score col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <h3 class=\"page-title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"ready",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":5,"column":31},"end":{"line":5,"column":44}}}))
    + "</h3>\n        <div class=\"score-cloud\">\n          <div class=\"score-cloud-scores\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"scores") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":8,"column":12},"end":{"line":13,"column":21}}})) != null ? stack1 : "")
    + "          </div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"hasImprovements") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(20, data, 0),"data":data,"loc":{"start":{"line":15,"column":10},"end":{"line":27,"column":17}}})) != null ? stack1 : "")
    + "          <img class=\"deco\" src=\"images/deco-shape.png\" alt=\"\" width=\"97.5\" height=\"25.5\">\n        </div>\n\n        <div class=\"score-info\">\n          <div class=\"logo\">\n            <img src=\"images/logo.svg\" alt=\"Logo MijnBreincoach\" width=\"151\" height=\"91\">\n          </div>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"kioskMode") : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(24, data, 0),"data":data,"loc":{"start":{"line":36,"column":10},"end":{"line":44,"column":17}}})) != null ? stack1 : "")
    + "          <a class=\"redo\" href=\"#short-libra\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"retake",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":45,"column":46},"end":{"line":45,"column":60}}}))
    + "</a>\n        </div>\n      </article>\n    </div>\n  </div>\n</div>\n<!--\n<div class=\"page-content\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <ul class=\"listing listing-checks\">\n          <li class=\"list-item\">\n            <i class=\"icon-check\"></i>\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"referral_list_work",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":59,"column":18},"end":{"line":59,"column":44}}}))
    + "</span>\n          </li>\n          <li class=\"list-item\">\n            <i class=\"icon-check\"></i>\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"referral_list_advice",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":63,"column":18},"end":{"line":63,"column":46}}}))
    + "</span>\n          </li>\n          <li class=\"list-item\">\n            <i class=\"icon-check\"></i>\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"referral_list_subject",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":67,"column":18},"end":{"line":67,"column":47}}}))
    + "</span>\n          </li>\n          <li class=\"list-item\">\n            <i class=\"icon-check\"></i>\n            <span>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"referral_list_messgae",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":71,"column":18},"end":{"line":71,"column":47}}}))
    + "</span>\n          </li>\n        </ul>\n      </article>\n    </div>\n  </div>\n</div>\n-->\n";
},"useData":true})
export var snippet = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"page-content kraak-noot-container\">\n    <div class=\"container-fluid\">\n      <div class=\"row\">\n        <article class=\"mainContent kraak-noot col-xs-12 col-sm-10 col-sm-offset-1\">\n          <div class=\"noot-head\">\n            <div class=\"date\">\n              <span class=\"day\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"day") || (depth0 != null ? lookupProperty(depth0,"day") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"day","hash":{},"data":data,"loc":{"start":{"line":8,"column":32},"end":{"line":8,"column":39}}}) : helper)))
    + "</span>\n              <span class=\"month\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"month") || (depth0 != null ? lookupProperty(depth0,"month") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"month","hash":{},"data":data,"loc":{"start":{"line":9,"column":34},"end":{"line":9,"column":43}}}) : helper)))
    + "</span>\n            </div>\n          </div>\n          <div class=\"noot-content\">\n            <a class=\"noot-link\" href=\"#\">\n              <div class=\"noot-full\">\n                <img class=\"noot-top\" src=\"images/kraak-noot-top.png\" alt=\"\" width=\"251\" height=\"189\">\n                <img class=\"noot-bottom\" src=\"images/kraak-noot-bottom.png\" alt=\"\" width=\"251\" height=\"189\">\n              </div>\n              <div class=\"noot-shadow\">\n                <img src=\"images/kraak-schaduw.png\" alt=\"\" width=\"215\" height=\"32\">\n              </div>\n            </a>\n          </div>\n          <a class=\"btn btn-secondary noot-link\" href=\"#\">"
    + alias4((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"bust_nut",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":23,"column":58},"end":{"line":23,"column":74}}}))
    + "</a>\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"nextNutIsLibra") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":10},"end":{"line":35,"column":17}}})) != null ? stack1 : "")
    + "        </article>\n      </div>\n    </div>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isDraft") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data,"loc":{"start":{"line":26,"column":12},"end":{"line":34,"column":19}}})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class=\"nut-percentage\">\n                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"completed",{"name":"t","hash":{"progress":(depth0 != null ? lookupProperty(depth0,"isDraft") : depth0)},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":50}}}))
    + "\n              </p>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <p class=\"nut-description\">\n                "
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"snippet_nut_description",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":32,"column":16},"end":{"line":32,"column":47}}}))
    + "\n              </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isLoaded") : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":40,"column":0},"end":{"line":141,"column":0}}})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"theme") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(30, data, 0),"data":data,"loc":{"start":{"line":41,"column":2},"end":{"line":140,"column":9}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"bg-primary\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <article class=\"mainContent beloning col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n            <div class=\"themaHead\">\n                <h1 class=\"title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"well_done",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":47,"column":34},"end":{"line":47,"column":52}}}))
    + "</h1>\n              </div>\n              <div class=\"rewardContent\">\n                <div class=\"rewards-points\">\n                  <img src=\"images/noten-rewards.png\" alt=\""
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"nut_rewards_alt",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":51,"column":59},"end":{"line":51,"column":82}}}))
    + "\" width=\"261\" height=\"174\">\n                  <div class=\"points\">\n                    <span class=\"nuts-cracked\"><span>"
    + alias3(((helper = (helper = lookupProperty(helpers,"rewardCompletedCount") || (depth0 != null ? lookupProperty(depth0,"rewardCompletedCount") : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"rewardCompletedCount","hash":{},"data":data,"loc":{"start":{"line":53,"column":53},"end":{"line":53,"column":77}}}) : helper)))
    + "</span></span>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bonus") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":54,"column":20},"end":{"line":56,"column":27}}})) != null ? stack1 : "")
    + "                  </div>\n                </div>\n              </div>\n          </article>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"page-content\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <article class=\"mainContent single beloning col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n            <div class=\"game-intro\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"completed") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(17, data, 0),"data":data,"loc":{"start":{"line":70,"column":14},"end":{"line":95,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n\n            <div class=\"rewards\">\n              <ul class=\"listing listing-rewards listing-rewards-full\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"nutSubmissions") : depth0),{"name":"each","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":100,"column":16},"end":{"line":114,"column":25}}})) != null ? stack1 : "")
    + "              </ul>\n            </div>\n          </article>\n        </div>\n      </div>\n    </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                      <span class=\"bonus-points-earned\"><span>+"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"bonus") || (depth0 != null ? lookupProperty(depth0,"bonus") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bonus","hash":{},"data":data,"loc":{"start":{"line":55,"column":63},"end":{"line":55,"column":72}}}) : helper)))
    + "</span></span>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bonus") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":71,"column":16},"end":{"line":75,"column":23}}})) != null ? stack1 : "")
    + "\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"we_hope_motivation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":77,"column":19},"end":{"line":77,"column":45}}}))
    + "</p>\n                <p><a class=\"btn btn-primary themes-page-link\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"go_to_themes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":78,"column":72},"end":{"line":78,"column":92}}}))
    + "</a></p>\n\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_completed_with_bonus",{"name":"t","hash":{"bonus":(depth0 != null ? lookupProperty(depth0,"bonusString") : depth0),"rewardTotalCount":(depth0 != null ? lookupProperty(depth0,"rewardTotalCount") : depth0)},"data":data,"loc":{"start":{"line":72,"column":22},"end":{"line":72,"column":110}}})) != null ? stack1 : "")
    + "</h5>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_completed",{"name":"t","hash":{"rewardTotalCount":(depth0 != null ? lookupProperty(depth0,"rewardTotalCount") : depth0)},"data":data,"loc":{"start":{"line":74,"column":22},"end":{"line":74,"column":81}}})) != null ? stack1 : "")
    + "</h5>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"firstReward") : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(23, data, 0),"data":data,"loc":{"start":{"line":80,"column":14},"end":{"line":95,"column":14}}})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"bonus") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data,"loc":{"start":{"line":81,"column":16},"end":{"line":85,"column":23}}})) != null ? stack1 : "")
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"first_reward_with_bonus",{"name":"t","hash":{"bonus":(depth0 != null ? lookupProperty(depth0,"bonusString") : depth0)},"data":data,"loc":{"start":{"line":82,"column":22},"end":{"line":82,"column":73}}})) != null ? stack1 : "")
    + "</h5>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"first_reward",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":84,"column":22},"end":{"line":84,"column":44}}})) != null ? stack1 : "")
    + "</h5>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"bonus") : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data,"loc":{"start":{"line":88,"column":16},"end":{"line":92,"column":23}}})) != null ? stack1 : "")
    + "\n                <p>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"daily_nuts_explanation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":94,"column":19},"end":{"line":94,"column":49}}}))
    + "</p>\n              ";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_ongoing_with_bonus",{"name":"t","hash":{"bonus":(depth0 != null ? lookupProperty(depth0,"bonusString") : depth0),"rewardTotalCount":(depth0 != null ? lookupProperty(depth0,"rewardTotalCount") : depth0),"rewardCompletedCount":(depth0 != null ? lookupProperty(depth0,"rewardCompletedCount") : depth0)},"data":data,"loc":{"start":{"line":89,"column":22},"end":{"line":89,"column":150}}})) != null ? stack1 : "")
    + "</h5>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <h5>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"theme_ongoing",{"name":"t","hash":{"bonus":(depth0 != null ? lookupProperty(depth0,"bonusString") : depth0),"rewardTotalCount":(depth0 != null ? lookupProperty(depth0,"rewardTotalCount") : depth0),"rewardCompletedCount":(depth0 != null ? lookupProperty(depth0,"rewardCompletedCount") : depth0)},"data":data,"loc":{"start":{"line":91,"column":22},"end":{"line":91,"column":139}}})) != null ? stack1 : "")
    + "</h5>\n";
},"28":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <li class=\"list-item is-open\">\n                    <a href=\"#view-session/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":102,"column":43},"end":{"line":102,"column":49}}}) : helper)))
    + "\">\n                      <div class=\"image\">\n                        <img src=\"images/noot-open-"
    + alias4(((helper = (helper = lookupProperty(helpers,"imageNr") || (depth0 != null ? lookupProperty(depth0,"imageNr") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"imageNr","hash":{},"data":data,"loc":{"start":{"line":104,"column":51},"end":{"line":104,"column":62}}}) : helper)))
    + ".jpg\" alt=\"\" width=\"75\" height=\"75\">\n                      </div>\n                      <div class=\"info\">\n                        <h4 class=\"title\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":107,"column":42},"end":{"line":107,"column":51}}}) : helper)))
    + " - "
    + alias4(((helper = (helper = lookupProperty(helpers,"completedDate") || (depth0 != null ? lookupProperty(depth0,"completedDate") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"completedDate","hash":{},"data":data,"loc":{"start":{"line":107,"column":54},"end":{"line":107,"column":71}}}) : helper)))
    + "</h4>\n                        <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"13\" height=\"22\" viewBox=\"0 0 13 22\">\n                          <path fill=\"#FE8A0B\" fill-rule=\"evenodd\" d=\"M11.5255895,10.1597684 L2.59443158,0.477663158 C2.31874737,0.183031579 1.94643158,0.0144 1.54506316,0.000821052632 C1.52769474,0.000505263158 1.51064211,0.000189473684 1.49358947,0.000189473684 C1.10737895,0.000189473684 0.742326316,0.144189474 0.465694737,0.405347368 C0.185589474,0.663663158 0.0153789474,1.04545263 0.000852631579,1.45029474 C-0.0133578947,1.85418947 0.129063158,2.24861053 0.391168421,2.53282105 L8.32916842,11.1488211 L0.482115789,18.9974526 C0.208957895,19.2706105 0.0520105263,19.6587158 0.0520105263,20.0619789 C0.0520105263,20.4652421 0.209589474,20.8542947 0.483694737,21.1284 C0.768221053,21.4110316 1.14558947,21.5664 1.54695789,21.5664 C1.94895789,21.5664 2.32727368,21.4104 2.6118,21.1277684 L11.4902211,12.2496632 C11.7728526,11.9626105 11.9275895,11.5858737 11.9275895,11.1867158 C11.9275895,10.8093474 11.7848526,10.4446105 11.5255895,10.1597684\" transform=\"translate(.21 .21)\"/>\n                        </svg>\n                      </div>\n                    </a>\n                  </li>\n";
},"30":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"bg-primary\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <article class=\"mainContent beloning col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n            <div class=\"gameContent\">\n              <div class=\"game-info\">\n                <h1 class=\"title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"lets_get_started",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":128,"column":34},"end":{"line":128,"column":59}}}))
    + "</h1>\n                <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"rewards_explanation",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":129,"column":19},"end":{"line":129,"column":46}}}))
    + "</p>\n                <a class=\"btn btn-secondary themes-page-link\" href=\"#\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"go_to_themes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":130,"column":71},"end":{"line":130,"column":91}}}))
    + "</a>\n              </div>\n            </div>\n          </article>\n        </div>\n      </div>\n    </div>\n\n    <div class=\"page-content\">\n    </div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"hasSnippet") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":141,"column":7}}})) != null ? stack1 : "");
},"useData":true})
export var theme_detail = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"is-active btn\"><i class=\"icon-check\"></i><span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(alias1,"theme_activated",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":11,"column":75},"end":{"line":11,"column":98}}}))
    + "</span></div>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"justActivated") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":16},"end":{"line":14,"column":23}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                  <p>"
    + ((stack1 = (lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"first_nut_tomorrow",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":13,"column":21},"end":{"line":13,"column":49}}})) != null ? stack1 : "")
    + "</p>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                <div class=\"is-inactive\"><a class=\"btn btn-secondary btn-icon\" href=\"#\"><i class=\"icon-check\"></i><span>"
    + container.escapeExpression((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"activate_this_theme",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":16,"column":120},"end":{"line":16,"column":147}}}))
    + "</span></a></div>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content no-flex-grow bg-primary\">\n  <article class=\"mainContent thema\">\n    <div class=\"thema-head\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <div class=\"col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n            <h1 class=\"title\"><i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":7,"column":40},"end":{"line":7,"column":48}}}) : helper)))
    + "\"></i>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":7,"column":54},"end":{"line":7,"column":63}}}) : helper)))
    + "</h1>\n            <p>"
    + alias4(((helper = (helper = lookupProperty(helpers,"subtitle") || (depth0 != null ? lookupProperty(depth0,"subtitle") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":27}}}) : helper)))
    + "</p>\n            <div class=\"thema-status\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"active") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":10,"column":14},"end":{"line":17,"column":21}}})) != null ? stack1 : "")
    + "            </div>\n          </div>\n        </div>\n      </div>\n    </div>\n  </article>\n</div>\n\n<div class=\"page-content theme-detail\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <article class=\"mainContent single col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n        <div class=\"articleContent\">\n          <h3>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":31,"column":14},"end":{"line":31,"column":23}}}) : helper)))
    + "</h3>\n          "
    + ((stack1 = ((helper = (helper = lookupProperty(helpers,"description") || (depth0 != null ? lookupProperty(depth0,"description") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"description","hash":{},"data":data,"loc":{"start":{"line":32,"column":10},"end":{"line":32,"column":27}}}) : helper))) != null ? stack1 : "")
    + "\n        </div>\n      </article>\n    </div>\n  </div>\n</div>\n";
},"useData":true})
export var themes = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"thema-section "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isHoldOn") : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":18,"column":30},"end":{"line":18,"column":116}}})) != null ? stack1 : "")
    + "\">\n      <h3><span>"
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":19,"column":16},"end":{"line":19,"column":25}}}) : helper)))
    + "</span></h3>\n      <ul class=\"listing listing-themas\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"themes") : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":21,"column":8},"end":{"line":32,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "is-hold-on";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"isImprovement") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data,"loc":{"start":{"line":18,"column":56},"end":{"line":18,"column":109}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "is-improve";
},"7":function(container,depth0,helpers,partials,data) {
    return "is-monitor";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "          <li class=\"list-item"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isActive") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":22,"column":30},"end":{"line":22,"column":63}}})) != null ? stack1 : "")
    + "\">\n            <a href=\"#theme/"
    + alias4(((helper = (helper = lookupProperty(helpers,"id") || (depth0 != null ? lookupProperty(depth0,"id") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data,"loc":{"start":{"line":23,"column":28},"end":{"line":23,"column":34}}}) : helper)))
    + "\">\n              <div class=\"icon\">\n                "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"isImprovement") : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":16},"end":{"line":25,"column":88}}})) != null ? stack1 : "")
    + "\n                <i class=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"icon") || (depth0 != null ? lookupProperty(depth0,"icon") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"icon","hash":{},"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":34}}}) : helper)))
    + "\"></i>\n              </div>\n              <h5>"
    + alias4(((helper = (helper = lookupProperty(helpers,"title") || (depth0 != null ? lookupProperty(depth0,"title") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data,"loc":{"start":{"line":28,"column":18},"end":{"line":28,"column":27}}}) : helper)))
    + "</h5>\n              <i class=\"icon-chevron-right\"></i>\n            </a>\n          </li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " is-active";
},"12":function(container,depth0,helpers,partials,data) {
    return "<span class=\"improvement-indicator\">!</span>";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"page-content bg-primary no-flex-grow\">\n  <article class=\"mainContent thema\">\n    <div class=\"thema-head\">\n      <div class=\"container-fluid\">\n        <div class=\"row\">\n          <div class=\"col-xs-12 col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2\">\n            <h1 class=\"title\">"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"themes",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":7,"column":30},"end":{"line":7,"column":44}}}))
    + "</h1>\n            <p>"
    + alias3((lookupProperty(helpers,"t")||(depth0 && lookupProperty(depth0,"t"))||alias2).call(alias1,"see_results",{"name":"t","hash":{},"data":data,"loc":{"start":{"line":8,"column":15},"end":{"line":8,"column":34}}}))
    + "</p>\n          </div>\n        </div>\n      </div>\n    </div>\n  </article>\n</div>\n\n<div class=\"page-content\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"sections") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":17,"column":2},"end":{"line":35,"column":11}}})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true})