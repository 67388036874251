'use strict';

import Backbone from 'backbone';
import I18n from '../utils/i18n'

export default Backbone.Model.extend({
  url: function () {
    // The language is already passed in Session.request(), but we do it here to have the results cached per language.
    return 'v2/config?l=' + I18n.getLanguage();
  },

  defaults: {
    themeCategories: [],
    privacyStatementUrl: '',
    regions: [],
  }

});
