'use strict';

import Backbone from 'backbone';

import NutSubmissionModel from '../models/nut_submission';

export default Backbone.Collection.extend({
  model: NutSubmissionModel,

  url: function () {
    return 'v2/completed_nuts/' + encodeURIComponent(this.themeIds.join(','));
  },

  themeIds: []
});
