'use strict';

import Backbone from 'backbone';
import _ from 'underscore';

export default Backbone.Model.extend({

  defaults: {
    id: 0,
    key: '',
    type: null,
    text: '',
    question: '',
    required: false,
    is_first: false,
    is_completed: false,
    theme_title: '',
    nut_title: '',
    answer: '',
    options: [],
    progress: 0,
    regex_pattern: '',
    min_value: null,
    max_value: null,
    states: {},
    none_above_value: null,
    maxLength: null,
    defaultValue: null,
    description: '',
  },

  updateOptions: function () {
    let answer = this.attributes.answer;
    if (typeof answer === 'string') {
      answer = [answer];
    }
    if (this.attributes.type === 'multipleChoice' || this.attributes.type === 'multiSelect') {
      for (let i in this.attributes.options) {
        this.attributes.options[i].checked = _.indexOf(answer, this.attributes.options[i].id) !== -1;
      }
    }
  },

  isRequired: function () {
    if (this.get('required')) {
      return true;
    }

    if (typeof this.attributes.states.required !== 'undefined' && this.collection) {
      // Check state to determine whether this should be required.
      return this.collection.haveStateConditionsBeenMet(this.attributes.states.required);
    }

    return false;
  }

});
