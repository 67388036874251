'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    if (this.attributes.uid) {
      return 'user/' + encodeURIComponent(this.attributes.uid);
    } else {
      return 'user/register';
    }
  },

  idAttribute: 'uid',
  defaults: {
    uid: null,
    username: '',
    email: '',
    existing_pass: '',
    pass: '',
    gender: '',
    age: '',
    pdp_participant: 0,
    region: '',
    language: '',
    contact_activities_events: 0,
    contact_studies: 0,
  },

  apiMapping: {
    uid: 'uid',
    username: 'name',
    email: 'mail',
    pass: 'pass',
    gender: 'field_gender',
    birthdate: 'field_birthdate',
    pdp_participant: 'field_pdp_participant',
    region: 'field_region',
    language: 'preferred_langcode',
    contact_activities_events: 'field_contact_activities_events',
    contact_studies: 'field_contact_studies',
  },

  parse: function (data) {
    let transformedData = {};
    for (let i in this.apiMapping) {
      if (data.hasOwnProperty(this.apiMapping[i]) && data[this.apiMapping[i]].length > 0 && typeof data[this.apiMapping[i]][0].value !== 'undefined') {
        transformedData[i] = data[this.apiMapping[i]][0].value;
      }
    }
    return transformedData;
  },

  toJSON: function () {
    let transformedData = {};
    for (let i in this.apiMapping) {
      // Never send uid back to the server.
      if (i !== 'uid') {
        transformedData[this.apiMapping[i]] = [{
          value: this.get(i)
        }];
      }
    }
    if (this.get('existing_pass')) {
      let passField = transformedData.pass ? transformedData.pass : [{}];
      passField[0].existing = this.get('existing_pass');
      transformedData.pass = passField;
    }
    return transformedData;
  }

});
