'use strict';

import Marionette from 'backbone.marionette';
import _ from 'underscore';


export default Marionette.Region.extend({
  attachHtml: function(view) {
    let animateClass = this.options.animateClass;

    view.$el.addClass('animate-in ' + animateClass);
    this.$el.append(view.el);

    // TODO: Mutation API for observing append completion
    setTimeout(function() {
      view.$el.removeClass(animateClass);
      view.$el.removeClass('animate-in');

      if (this.oldView) {
        this.oldView.$el.addClass('animate-out');
        this.oldView.triggerMethod('transitionOut', this, this.oldView);

        if (this.oldView.bodyClass) {
          jQuery('body').removeClass(this.oldView.bodyClass);
        }
      }

      if (this.currentView) {
        if (this.currentView.bodyClass) {
          jQuery('body').addClass(this.currentView.bodyClass);
        }
        this.currentView.triggerMethod('transitionIn', this, this.currentView);
      }
    }.bind(this), 34);
  },

  // Overridden from base class.
  show: function(view, options) {
    this.options.animateClass = options.animateClass;
    if (!this.$el.length) {
      Marionette.Region.prototype.show.call(this, view, options);
    } else {
      this.oldView = this.currentView;

      this.oldView.$el.addClass(options.animateClass);
      this.oldView.$el.one('transitionend', function() {
        this.oldView.destroy();
      }.bind(this));

      Marionette.Region.prototype.show.call(this, view, _.extend(options, {preventDestroy: true}));
    }
  },

  // Overridden from base class.
  empty: function(options) {
    const view = this.currentView;

    // If there is no view in the region we should not remove anything.
    if (!view) {
      if (this._ensureElement(options)) {
        this.detachHtml();
      }
      return this;
    }

    view.off('destroy', this.empty, this);

    return this;
  }
});
