import App from '../app';
import Validator from 'validatorjs';

class I18n {
  constructor() {
    this.strings = {
      'nl': { //Default
        '_title': 'Nederlands',
        'activate_this_theme': 'Maak dit thema actief',
        'and': 'en',
        'answer': 'antwoord',
        'app_name': 'MijnBreincoach',
        'back': 'Terug',
        'birth_date': 'Geboortedatum',
        'bonus_plural': '@count bonuspunten',
        'bonus_singular': '1 bonuspunt',
        'bust_nut': 'Kraak de noot',
        'cancel': 'Annuleren',
        'change_password': 'Wachtwoord wijzigen',
        'change': 'Verander',
        'chatui_continue': 'Verder',
        'chatui_ok': 'Okay',
        'choose_one': '- Selecteer -',
        'close': 'Sluiten',
        'completed': 'Voltooid',
        'connection_msg':'De internetverbinding is verbroken. Om juist te functioneren heeft MijnBreincoach een internetverbinding nodig.',
        'contact_activities_events_consent': '',
        'contact_studies_consent': '',
        'current_password': 'Huidig wachtwoord',
        'daily_nuts_explanation': 'Je zult nieuwe noten blijven ontvangen tot je alle actieve thema\'s hebt afgerond.',
        'date_days': '@days',
        'datepicker_overlay_button': 'Versturen',
        'datepicker_overlay_placeholder': 'Voer een jaar in',
        'day_fri': 'Vrij',
        'day_mon': 'Maa',
        'day_sat': 'Zat',
        'day_sun': 'Zon',
        'day_thu': 'Don',
        'day_tue': 'Din',
        'day_wed': 'Woe',
        'email': 'E-mailadres',
        'enter_email_and_password': 'Voer een e-mailadres en wachtwoord in.',
        'enter_your_email': 'Voer je e-mailadres in.',
        'error_cannot_complete_password_request': 'De wachtwoordaanvraag kon niet worden voltooid.',
        'error_email_or_password_incorrect': 'Het e-mailadres of het wachtwoord zijn onjuist.',
        'error_incorrect_temporary_password': 'Het tijdelijke wachtwoord is niet correct.',
        'error_login_failed_check_connection': 'Inloggen is mislukt. Controleer de internetverbinding en probeer het opnieuw.',
        'error_login_failed': 'Kan niet inloggen.',
        'error_password_requirements': 'Het wachtwoord voldoet niet aan de eisen.',
        'error_profile_save_failed': 'Er is een fout opgetreden bij het opslaan van het profiel.',
        'error_save_theme': 'Er is een fout opgetreden bij het instellen van het thema.',
        'error_send_answer':'Er is een fout opgetreden bij het versturen van het antwoord.',
        'error_start_test':'Er is een fout opgetreden bij het starten van de LIBRA test.',
        'female': 'Vrouw',
        'first_nut_tomorrow': 'Morgen ontvang je een nieuwe noot om meer te ontdekken over deze risicofactor. Je kunt ook meerdere thema\'s tegelijk activeren om dagelijks meldingen over deze thema\'s te ontvangen. Voor meer informatie, bezoek de infopagina\'s. Klik op de titels (met een blauwe achtergrond) op de infopagina\'s om de verschillende onderwerpen te zien.<br /><br />Als je een mobiel apparaat gebruikt, zorg er dan voor dat het is ingesteld om meldingen te ontvangen.',
        'first_reward_with_bonus': 'Je hebt nu <strong>de eerste noot</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Morgen staat er weer een nieuwe noot voor je klaar!',
        'first_reward': 'Je hebt nu <strong>de eerste noot</strong> gekraakt. Morgen staat er weer een nieuwe noot voor je klaar!',
        'forgot_password': 'Wachtwoord vergeten?',
        'form_contains_errors': 'Het formulier bevat de volgende fouten:\n',
        'form_password_no_lower_case': 'Het wachtwoord bevat geen kleine letters.',
        'form_password_no_numeric': 'Het wachtwoord bevat geen cijfers.',
        'form_password_no_special': 'Het wachtwoord bevat geen speciale karakters.',
        'form_password_no_upper_case': 'Het wachtwoord bevat geen hoofdletters.',
        'form_password_too_long': 'Het wachtwoord is te lang.',
        'form_password_too_short': 'Het wachtwoord is te kort.',
        'gather_point_before': 'Verzamel 1 punt voor @date',
        'gather_points_before': 'Verzamel @count punten voor @date',
        'gender': 'Geslacht',
        'go_to_themes': 'Naar de thema\'s',
        'good_job': 'Goed bezig!',
        'i_am_pdp_participant': 'Ik ben al een PDP deelnemer',
        'i_have_an_account': 'Ik heb al een account',
        'i_will_participate': 'Ik doe mee',
        'information': 'Informatie',
        'join': 'Ik doe mee',
        'language': 'Taal',
        'lets_get_started': 'Laten we beginnen',
        'login': 'Inloggen',
        'logo_alt': 'Logo MijnBreincoach',
        'logout': 'Uitloggen',
        'make_free_account': 'Ga voor gezonde hersenen! Maak nu een eigen account aan en krijg gratis toegang tot de MijnBreincoach app.',
        'male': 'Man',
        'max_selected':'Selecteer maximaal :max_selected opties in het :attribute veld.',
        'min_digits': 'Het :attribute veld moet minimaal :min_digits cijfer(s) bevatten.',
        'min_lowers':'Het :attribute veld moet minimaal :min_lowers kleine letter(s) bevatten.',
        'min_selected':'Selecteer minimaal :min_selected opties in het :attribute veld.',
        'min_specialchars':'Het :attribute veld moet minimaal :min_specialchars speciale karakter(s) bevatten.',
        'min_uppers': 'Het :attribute veld moet minimaal :min_uppers hoofdletter(s) bevatten',
        'month_apr': 'Apr',
        'month_aug': 'Aug',
        'month_dec': 'Dec',
        'month_feb': 'Feb',
        'month_full_apr': 'April',
        'month_full_aug': 'Augustus',
        'month_full_dec': 'December',
        'month_full_feb': 'Februari',
        'month_full_jan': 'Januari',
        'month_full_jul': 'Juli',
        'month_full_jun': 'Juni',
        'month_full_mar': 'Maart',
        'month_full_may': 'Mei',
        'month_full_nov': 'November',
        'month_full_oct': 'Oktober',
        'month_full_sep': 'September',
        'month_jan': 'Jan',
        'month_jul': 'Jul',
        'month_jun': 'Jun',
        'month_mar': 'Mrt',
        'month_may': 'Mei',
        'month_nov': 'Nov',
        'month_oct': 'Okt',
        'month_sep': 'Sep',
        'more_info': 'Meer info',
        'name': 'Naam',
        'navigation_error':'Er is een fout opgetreden bij het navigeren.',
        'new_password_confirmation': 'Nieuw wachtwoord (bevestiging)',
        'new_password': 'Nieuw wachtwoord',
        'next': 'Volgende',
        'no': 'Nee',
        'nut_of_the_day': 'Noot van de dag',
        'nut_rewards_alt': 'Gekraakte noten',
        'other': 'Anders',
        'password_confirmation': 'Wachtwoord (bevestiging)',
        'password_request_sent': 'De wachtwoordaanvraag is verstuurd naar jouw e-mailadres.',
        'password_requirements_hint': 'Een veilig wachtwoord bestaat uit minimaal 8 karakters, waarvan minstens 1 cijfer, 1 kleine letter, 1 hoofdletter en 1 leesteken.',
        'password': 'Wachtwoord',
        'phone_number': 'Telefoonnummer',
        'postal_code_only_digits': 'Vul alleen de vier cijfers in.',
        'postal_code': 'Postcode',
        'previous_question': 'Vorige vraag',
        'previous': 'Vorige',
        'privacy_consent_explanation': 'Deze gegevens zijn nodig om gebruik te maken van MijnBreincoach. De regels voor de opslag en gebruik van de gegevens staan beschreven in de privacyverklaring.',
        'privacy_consent': 'Ik geef MijnBreincoach toestemming om de door mij ingevulde persoonsgegevens te bewaren.',
        'profile': 'Profiel',
        'read_privacy_statement': 'Lees hier onze privacyverklaring.',
        'ready': 'Klaar!',
        'referral_list_advice': 'Je krijgt persoonlijk advies',
        'referral_list_messgae': 'Je ontvangt iedere dag een kort berichtje: een ‘weetje’, quizvraag, tip of uitdaging',
        'referral_list_subject': 'Je kiest zelf waar, wanneer en met welk onderwerp je aan de slag gaat',
        'referral_list_work': 'Je werkt op een leuke manier aan gezonde(re) hersenen',
        'region': 'Regio',
        'register_for_more': 'Wil je op een leuke manier aan de slag met jouw verbeterpunten en weten hoe je scoort op alle thema’s? Maak dan nu een account aan!',
        'request_new_password': 'Nieuw wachtwoord aanvragen',
        'reset_password_instructions': 'Stel je nieuwe wachtwoord in. Vul hieronder het tijdelijke wachtwoord dat je per e-mail hebt ontvangen in, en vul een nieuw wachtwoord in.',
        'reset_password': 'Wachtwoord opnieuw instellen',
        'resume_later': 'Ja, ga later verder',
        'retake': 'Test opnieuw invullen',
        'rewards_explanation': 'Bedankt voor het invullen. De volgende stap is het bekijken van de thema\'s waarbij er voor jou ruimte voor verbetering is. Selecteer een thema waaraan je wil gaan werken en je ontvangt iedere dag een nieuwe tip, vraag of handig weetje over dit thema.',
        'save': 'Opslaan',
        'see_results': 'Bekijk jouw uitkomsten en activeer het thema waaraan je gaat werken.',
        'see_your_results': 'Klaar',
        'short_test_restrict':'De korte LIBRA test is niet beschikbaar wanneer je ingelogd bent.',
        'start_app': 'Start MijnBreincoach',
        'stop_test': 'Stoppen met de test',
        'subscribe_to_newsletter_link': 'Schrijf je in voor de nieuwsbrief',
        'subscribe_to_newsletter': 'Schrijf mij in voor de nieuwsbrief',
        'system_notification_ignore': 'Negeer',
        'system_notification_view': 'Bekijk',
        'temp_password': 'Tijdelijk wachtwoord',
        'test_result_good': 'Uit de snelle test blijkt dat je goed bezig bent. De MijnBreincoach app helpt je om je hersengezondheid op peil te houden.',
        'test_result_improve_after': '',
        'test_result_improve_plural': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
        'test_result_improve_singular': 'Uit jouw snelle test blijkt dat er ruimte voor verbetering is op het gebied van',
        'test_result_keep_up': ', hou dit zo vol!',
        'test_result_maintain': 'In het groen zie je waar je goed op scoort, namelijk',
        'thanks_result_page': 'Bedankt voor het invullen van de snelle test! Wil je op een leuke manier aan de slag met jouw verbeterpunten? Ga dan naar mijnbreincoach.eu of download de app.',
        'theme_activated': 'Dit thema is nu actief',
        'theme_completed_with_bonus': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt en daarmee <span class="highlighted">@bonus</span> verdiend. Je hebt nu alle noten voor dit thema afgerond.',
        'theme_completed': 'Gefeliciteerd! Je hebt <strong>alle @rewardTotalCount noten</strong> gekraakt. Je hebt nu alle noten voor dit thema afgerond.</p>',
        'theme_ongoing_with_bonus': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt en tot nu toe <span class="highlighted">@bonus</span> verdiend. Open iedere dag een nieuwe noot!',
        'theme_ongoing': 'Je hebt al <strong>@rewardCompletedCount van de @rewardTotalCount noten</strong> gekraakt. Open iedere dag een nieuwe noot!',
        'theme_switching_explanation': 'Heb je eerder aan een ander thema gewerkt en zou je graag deze noten nog eens teruglezen? Dit kun je doen door dat thema weer even te activeren.',
        'themes': 'Thema\'s',
        'want_to_resume_later': 'Wil je je antwoorden opslaan en later verder gaan?',
        'we_hope_motivation': 'We hopen dat er iets tussen zat wat je motivatie heeft versterkt om je hersengezondheid goed in de gaten te blijven houden. Als je wilt, kun je terugkeren naar je scoreoverzicht en beslissen of je aan een ander thema wilt gaan werken in de komende weken.',
        'well_done': 'Goed bezig!',
        'you_are_logged_out': 'Je bent nu uitgelogd.',
        'you_can_login_using_new_password': 'Je kunt nu inloggen met je nieuwe wachtwoord.',

        'snippet_nut_description': 'Je eerste noot is een vragenlijst om de app voor jou te personaliseren. De volgende noten zijn kort en makkelijk!',
      },
      'en': { //English
        '_title': 'English',
        'activate_this_theme': 'Activate this topic',
        'and': 'and',
        'answer': 'answer',
        'app_name': 'My Braincoach',
        'back': 'Back',
        'birth_date': 'Birthdate',
        'bonus_plural': '@count bonuspoints',
        'bonus_singular': '1 bonuspoint',
        'bust_nut': 'Crack the nut',
        'cancel': 'Cancel',
        'change_password': 'Change password',
        'change': 'Change',
        'chatui_continue': 'Continue',
        'chatui_ok': 'Okay',
        'choose_one': '- Select -',
        'close': 'Close',
        'completed': '@progress completed',
        'connection_msg':'No internet connection. MyBraincoach requires an active connection to function.',
        'contact_activities_events_consent': 'I would like to be contacted regarding relevant activities and events organised by the <em>pdp</em> team aligned with dementia prevention and training.',
        'contact_studies_consent': 'I agree to being contacted for possible participation in future studies.',
        'current_password': 'Current password',
        'daily_nuts_explanation': 'You will continue to receive the daily nuts until you have completed all active topics.',
        'date_days': '@days',
        'datepicker_overlay_button': 'Submit',
        'datepicker_overlay_placeholder': 'Enter a year',
        'day_fri': 'Fri',
        'day_mon': 'Mon',
        'day_sat': 'Sat',
        'day_sun': 'Sun',
        'day_thu': 'Thu',
        'day_tue': 'Tue',
        'day_wed': 'Wed',
        'email': 'E-mail address',
        'enter_email_and_password': 'Enter an email address and password.',
        'enter_your_email': 'Enter your email address.',
        'error_cannot_complete_password_request': 'The password request could not be completed.',
        'error_email_or_password_incorrect': 'The email address or password is incorrect.',
        'error_incorrect_temporary_password': 'The temporary password is incorrect.',
        'error_login_failed_check_connection': 'Login failed. Please check your internet connection and try again.',
        'error_login_failed': 'Login failed.',
        'error_password_requirements': 'The password does not meet the requirements.',
        'error_profile_save_failed': 'Saving the profile failed.',
        'error_save_theme': 'An error has been encountered while saving the theme.',
        'error_send_answer': 'An error occurred, the answer could not be send.',
        'error_start_test':'An error occurred while starting the LIBRA test.',
        'female': 'Female',
        'first_nut_tomorrow': 'Tomorrow, you will receive a new nut to discover more about this risk factor. You can also activate multiple themes at the same time to receive daily notifications on these themes. For more information, visit the infopages. In the infopages, click on the titles (with a blue background) to see the different topics.<br /><br />If you are using a mobile device, please make sure it\'s set to receive notifications.',
        'first_reward_with_bonus': 'You have now cracked <strong>the first nut</strong> and earned <span class="highlighted">@bonus</span> so far. Tomorrow a new nut will be waiting for you!',
        'first_reward': 'You have now cracked <strong>the first nut</strong>. Tomorrow a new nut will be waiting for you!',
        'forgot_password': 'Forgot password?',
        'form_contains_errors': 'The form contains the following errors:\n',
        'form_password_no_lower_case': 'The password does not contain any lower case characters.',
        'form_password_no_numeric': 'The password does not contain any numeric characters.',
        'form_password_no_special': 'The password does not contain any special characters.',
        'form_password_no_upper_case': 'The password does not contain any uppercase characters.',
        'form_password_too_long': 'The password is too long.',
        'form_password_too_short': 'The password is too short.',
        'gather_point_before': 'Gather 1 point before @date',
        'gather_points_before': 'Gather @count points before @date',
        'gender': 'Gender',
        'go_to_themes': 'Visit the topics',
        'good_job': 'Good job!',
        'i_am_pdp_participant': 'I am a <em>pdp</em> participant (<a href="https://pdp.lu/en/" target="_blank">Programme for Dementia Prevention</a>)',
        'i_have_an_account': 'I already have an account',
        'i_will_participate': 'I will participate',
        'information': 'Information',
        'join': 'I will participate',
        'language': 'Language',
        'lets_get_started': 'Let\'s get started',
        'login': 'Login',
        'logo_alt': 'Logo My Brain Coach',
        'logout': 'Log Out',
        'make_free_account': 'Go for a healthy brain! Create your own account now and get free access to the <em>pdp</em> Braincoach app.',
        'male': 'Male',
        'max_selected':'Select :max_selected options at maximum in the :attribute field.',
        'min_digits': 'The field :attribute should contain a minimum of :min_digits digits.',
        'min_lowers':'The field :attribute  should contain a minimum of :min_lowers lowercase letters.',
        'min_selected':'Select at least :min_selected options in the :attribute field.',
        'min_specialchars':'The field :attribute  should contain a minimum of :min_specialchars special characters.',
        'min_uppers':'The field :attribute  should contain a minimum of :min_uppers Uppercase letters.',
        'month_apr': 'Apr',
        'month_aug': 'Aug',
        'month_dec': 'Dec',
        'month_feb': 'Feb',
        'month_full_apr': 'April',
        'month_full_aug': 'August',
        'month_full_dec': 'December',
        'month_full_feb': 'February',
        'month_full_jan': 'January',
        'month_full_jul': 'July',
        'month_full_jun': 'June',
        'month_full_mar': 'March',
        'month_full_may': 'May',
        'month_full_nov': 'November',
        'month_full_oct': 'October',
        'month_full_sep': 'September',
        'month_jan': 'Jan',
        'month_jul': 'Jul',
        'month_jun': 'Jun',
        'month_mar': 'Mar',
        'month_may': 'May',
        'month_nov': 'Nov',
        'month_oct': 'Oct',
        'month_sep': 'Sep',
        'more_info': 'More info',
        'name': 'Name',
        'navigation_error':'A navigation error occurred.',
        'new_password_confirmation': 'New password (confirmation)',
        'new_password': 'New password',
        'next': 'Next',
        'no': 'No',
        'nut_of_the_day': 'Nut of the day',
        'nut_rewards_alt': 'Cracked nuts',
        'other': 'Other',
        'password_confirmation': 'Password (confirmation)',
        'password_request_sent': 'The password request has been sent to your email address.',
        'password_requirements_hint': 'A secure password consists of at least 8 characters, of which at least 1 number, 1 lowercase letter, 1 capital letter and 1 punctuation mark.',
        'password': 'Password',
        'phone_number': 'Phone number',
        'postal_code_only_digits': 'Only enter the four digits.',
        'postal_code': 'Postal code',
        'previous_question': 'Previous question',
        'previous': 'Previous',
        'privacy_consent_explanation': ', including to the processing of my personal data to use the <em>pdp</em> Braincoach app (*).',
        'privacy_consent': 'I have read and agree to the <em>pdp</em> Braincoach ',
        'profile': 'Profile',
        'quit': 'Quit',
        'read_privacy_statement': 'User Information Sheet (including Privacy Policy) and Electronic Informed Consent Form',
        'ready': 'Ready!',
        'referral_list_advice': 'You get personal advice',
        'referral_list_messgae': 'You will receive a short message every day: a \'fact\', quiz question, tip or challenge',
        'referral_list_subject': 'You choose where, when and with which theme you will work',
        'referral_list_work': 'You work on a healthy(er) brain in a fun way ',
        'region': 'Region',
        'register_for_more': 'Do you want to get started with your points for improvement in a fun way and know how you score on all topics? Create an account now!',
        'request_new_password': 'Request new password',
        'reset_password_instructions': 'Set your new password. Enter the temporary password you received by email below and enter a new password.',
        'reset_password': 'Reset password',
        'resume_later': 'Yes, resume later',
        'retake': 'Take the test again',
        'rewards_explanation': 'Thank you for filling in. The next step is to look at the topics where there is room for improvement. Select a topic you want to work on and you will receive a new tip, question or useful fact about this topic every day.',
        'save': 'Save',
        'see_results': 'View your results and activate the topic you are going to work on.',
        'see_your_results': 'Ready',
        'short_test_restrict':'The short LIBRA test is unavailable when logged in.',
        'start_app': 'Start My Brain Coach',
        'stop_test': 'Stop this test',
        'subscribe_to_newsletter_link': 'Schrijf je in voor de nieuwsbrief',
        'subscribe_to_newsletter': 'Subscribe me to the newsletter',
        'system_notification_ignore': 'Ignore',
        'system_notification_view': 'View',
        'temp_password': 'Temporary password',
        'test_result_good': 'Your short test shows that you are doing well. Keep this up!',
        'test_result_improve_after': '',
        'test_result_improve_plural': 'Your short test shows that there is room for improvement in the areas of ',
        'test_result_improve_singular': 'Your short test shows that there is room for improvement in the area of ',
        'test_result_keep_up': ', keep this up!',
        'test_result_maintain': 'In green you can see where you are doing well, namely on',
        'thanks_result_page': 'Thank you for completing the short test. We hope that you are motivated to keep your brain fit and that you will take on the challenge to start working on your room-for-improvement.',
        'theme_activated': 'This topic is now active',
        'theme_completed_with_bonus': 'Congratulations! You have cracked <strong>all @rewardTotalCount nuts</strong> for this topic and earned <span class="highlighted">@bonus</span>.',
        'theme_completed': 'Congratulations! You have cracked <strong>all @rewardTotalCount nuts</strong>. You have now completed all nuts for this topic.',
        'theme_ongoing_with_bonus': 'You have already cracked <strong>@rewardCompletedCount from the @rewardTotalCount nuts</strong> and earned <span class="highlighted">@bonus</span> (from answering a quiz or challenge question). Open a new nut every day!',
        'theme_ongoing': 'You have already cracked <strong>@rewardCompletedCount from the @rewardTotalCount nuts</strong>. Open a new nut every day!',
        'theme_switching_explanation': 'Have you worked on a different theme before and would you like to read these nuts again? You can do this by activating that theme again.',
        'themes': 'Themes',
        'want_to_resume_later': 'Would you like to save your answers to continue later?',
        'we_hope_motivation': 'We hope you found something that boosted your motivation to keep a close eye on your brain health. If you like, you can return to your scoresheet and decide if you want to work on a different topic in the coming weeks.',
        'well_done': 'Well done!',
        'you_are_logged_out': 'You are logged out now.',
        'you_can_login_using_new_password': 'You can now login using your new password.',

        'snippet_nut_description': 'Your first nut will be a questionnaire to personalise the app for you. The following nuts will be short and easy!',
      },
      'da': { //Danish
        '_title': 'Dansk',
        'activate_this_theme': 'Aktiver kategori',
        'and': 'og',
        'answer': 'svar',
        'app_name': 'Hjernesund-testen',
        'back': 'Tilbage',
        'birth_date': 'Fødselsdato',
        'bonus_plural': '@count bonuspoint',
        'bonus_singular': '1 bonuspoint',
        'bust_nut': 'Knæk nødden',
        'cancel': 'Annuller',
        'change_password': 'Skift kodeord',
        'change': 'Rediger',
        'chatui_continue': 'Fortsæt',
        'chatui_ok': 'Okay',
        'choose_one': '-Vælg-',
        'close': 'Luk',
        'completed': 'Afsluttet',
        'connection_msg':'Ingen forbindelse. Hjernesund-testen kræver en internetforbindelse for at fungere.',
        'contact_activities_events_consent': '',
        'contact_studies_consent': '',
        'current_password': 'Nuværende adgangskode',
        'daily_nuts_explanation': 'You will continue to receive the daily nuts until you have completed all active topics.',
        'date_days': '@days.',
        'datepicker_overlay_button': 'Send',
        'datepicker_overlay_placeholder': 'Indtast et år',
        'day_fri': 'Fre',
        'day_mon': 'Man',
        'day_sat': 'Lør',
        'day_sun': 'Søn',
        'day_thu': 'Tor',
        'day_tue': 'Tir',
        'day_wed': 'Ons',
        'email': 'E-mail-adresse',
        'enter_email_and_password': 'Indtast en e-mailadresse og adgangskode.',
        'enter_your_email': 'Indtast din e-mailadresse.',
        'error_cannot_complete_password_request': 'Anmodningen om adgangskode kunne ikke gennemføres.',
        'error_email_or_password_incorrect': 'E-mailadressen eller adgangskoden er forkert.',
        'error_incorrect_temporary_password': 'Den midlertidige adgangskode er forkert.',
        'error_login_failed_check_connection': 'Login mislykkedes. Kontroller din internetforbindelse, og prøv igen.',
        'error_login_failed': 'Login mislykkedes.',
        'error_password_requirements': 'Adgangskoden opfylder ikke kravene.',
        'error_profile_save_failed': 'Der er opstået en fejl under lagring af din profil.',
        'error_save_theme': 'Der er opstået en fejl under lagring af temaet.',
        'error_send_answer':'Der er opståret en fejl. Svaret blev ikke sendt.',
        'error_start_test':'Der opstod en fejl ved starten af testen.',
        'female': 'Kvinde',
        'first_nut_tomorrow': 'I morgen vil du modtage en ny nød for at opdage mere om denne risikofaktor. Du kan også aktivere flere temaer på samme tid for at modtage daglige meddelelser om disse temaer. For mere information, besøg infosiderne. På infosiderne skal du klikke på titlerne (med blå baggrund) for at se de forskellige emner.<br/><br />Hvis du bruger en mobilenhed, skal du sørge for, at den er indstillet til at modtage notifikationer.',
        'first_reward_with_bonus': 'Du har nu knækket <strong>den første nød</strong> og optjent <span class="highlighted">@bonus</span> indtil videre. I morgen venter endnu en nød!',
        'first_reward': 'Du har nu knækket <strong>den første nød</strong>. I morgen venter endnu en nød!',
        'forgot_password': 'Glemt kodeord?',
        'form_contains_errors': 'Formularen indeholder følgende fejl:\n',
        'form_password_no_lower_case': 'Kodeordet indeholder ikke små bogstaver.',
        'form_password_no_numeric': 'Kodeordet indeholder inden tal.',
        'form_password_no_special': 'Kodeordet indeholder ingen specailtegn.',
        'form_password_no_upper_case': 'Kodeordet indeholder ikke store bogstaver.',
        'form_password_too_long': 'Kodeordet er for langt',
        'form_password_too_short': 'Kodeordet er for kort',
        'gather_point_before': 'Gather 1 point before @date',
        'gather_points_before': 'Gather @count points before @date',
        'gender': 'Køn',
        'go_to_themes': 'Gå til kategorier',
        'good_job': 'Godt arbejde!',
        'i_am_pdp_participant': 'Jeg er allerede PDP-deltager',
        'i_have_an_account': 'Log ind',
        'i_will_participate': 'Få dine personlige tips nu',
        'information': 'Information',
        'join': 'Ja tak til inspiration',
        'language': 'Sprog',
        'lets_get_started': 'Let\'s get started',
        'login': 'Log ind',
        'logo_alt': 'Logo Hjernesund-testen',
        'logout': 'Log ud',
        'make_free_account': 'Registrer dig til dit personlige forløb nu. Så vil du få daglige tips til at holde din hjerne sund de næste par uger på din mail.',
        'male': 'Mand',
        'max_selected':'Vælg max :max_selected svar i :attribute feltet.',
        'min_digits': 'Feltet :attribute skal indeholde mindst :min_digits cifre.',
        'min_lowers':'Feltet :attribute skal indeholde mindst :min_lowers små bogstaver.',
        'min_selected':'Vælg mindst :min_selected svar i :attribute feltet.',
        'min_specialchars':'Feltet :attribute  skal indeholde mindst :min_specialchars specialtegn..',
        'min_uppers':'Feltet :attribute  skal indeholde mindst :min_uppers store bogstaver.',
        'month_apr': 'Apr',
        'month_aug': 'Aug',
        'month_dec': 'Dec',
        'month_feb': 'Feb',
        'month_full_apr': 'April',
        'month_full_aug': 'August',
        'month_full_dec': 'December',
        'month_full_feb': 'Februar',
        'month_full_jan': 'Januar',
        'month_full_jul': 'Juli',
        'month_full_jun': 'Juni',
        'month_full_mar': 'Marts',
        'month_full_may': 'Maj',
        'month_full_nov': 'November',
        'month_full_oct': 'Oktober',
        'month_full_sep': 'September',
        'month_jan': 'Jan',
        'month_jul': 'Jul',
        'month_jun': 'Jun',
        'month_mar': 'Mar',
        'month_may': 'Maj',
        'month_nov': 'Nov',
        'month_oct': 'Okt',
        'month_sep': 'Sep',
        'more_info': 'More info',
        'name': 'Navn',
        'navigation_error':'Der er opståret en navigationsfejl',
        'new_password_confirmation': 'Ny adgangskode (bekræftelse)',
        'new_password': 'Ny adgangskode',
        'next': 'Næste',
        'no': 'Nej',
        'nut_of_the_day': 'Dagens nød',
        'nut_rewards_alt': 'Knækkede nødder',
        'other': 'Andet',
        'password_confirmation': 'Adgangskode (bekræftelse)',
        'password_request_sent': 'Anmodningen om adgangskode er blevet sendt til din e-mailadresse.',
        'password_requirements_hint': 'En sikker adgangskode består af mindst 8 tegn, heraf mindst et tal, et lille bogstav, et stort bogstav og et specialtegn.',
        'password': 'Adgangskode',
        'phone_number': 'Telefonnummer',
        'postal_code_only_digits': 'Indtast kun de fire cifre.',
        'postal_code': 'Postnummer',
        'previous_question': 'Forrige spørgsmål',
        'previous': 'Forrige',
        'privacy_consent_explanation': 'Disse oplysninger er nødvendige. Du kan læse hvordan vi bruger og opbevarer data i vores privatlivspolitik.',
        'privacy_consent': 'Jeg giver Alzheimerforeningen tilladelse til at gemme de oplysninger, jeg har indtastet ovenfor',
        'profile': 'Profil',
        'read_privacy_statement': 'Læs vores privatlivspolitik.',
        'ready': 'Knæk en nød og få dagens tip',
        'referral_list_advice': 'Du får personlige råd',
        'referral_list_messgae': 'Du modtager et kort tip hver dag med inspiration, personlige challenges,og gode råd',
        'referral_list_subject': 'Du vælger selv hvilket område, du vil arbejde videre med',
        'referral_list_work': 'Du arbejder på en sund(ere) hjerne på en sjov måde',
        'region': 'Område',
        'register_for_more': 'Få personlig inspiration og daglige tips til at holde din hjerne sund. Tilmeld dig og tag det første skridt nu.',
        'request_new_password': 'Bestil ny adgangskode',
        'reset_password_instructions': 'Indstil din nye adgangskode. Indtast den midlertidige adgangskode, du har modtaget via e-mail herunder, og indtast en ny adgangskode.',
        'reset_password': 'Reset adgangskode',
        'resume_later': 'Ja, genoptag senere',
        'retake': 'Tag testen igen',
        'rewards_explanation': 'Tak for din registrering. Det er nu tid til at se på de områder, hvor du har plads til forbedring. Vælg den eller de kategorier, du vil arbejde med, så vil du hver dag modtage et nyt tip med inspiration, personlige udfordringer og gode råd.',
        'save': 'Gem',
        'see_results': 'Se dine resultater og aktivér den eller de kategorier, du vil arbejde med. Du kan aktivere så mange kategorier, du vil. Hvis din test ikke viser nogle områder med plads til forbedring, kan du stadig aktivere kategorierne, hvis der er nogle områder, du godt kunne tænke dig at få mere inspiration inden for.',
        'see_your_results': 'Se dit resultat og få gode tips',
        'short_test_restrict':'Den korte LIBRA -test er ikke tilgængelig, når du er indlogeret.',
        'start_app': 'Få dine tips',
        'stop_test': 'Afbryd testen',
        'subscribe_to_newsletter_link': 'Jeg vil nøjes med at modtage Hjernesund-nyheder',
        'subscribe_to_newsletter': 'Skriv mig op til hjernesunde nyheder',
        'system_notification_ignore': 'Ignorer',
        'system_notification_view': 'Se',
        'temp_password': 'Midlertidig adgangskode',
        'test_result_good': 'Testen viser, at du klarer det godt. Stærkt! Få inspiration til at holde den gode stil i linket ovenfor.',
        'test_result_improve_after': '',
        'test_result_improve_plural': 'Din test viser, at der er plads til forbedringer på området/områderne',
        'test_result_improve_singular': 'Din test viser, at der er plads til forbedringer på området/områderne',
        'test_result_keep_up': '. Fortsæt den gode stil!',
        'test_result_maintain': 'Til gengæld klarer du det godt med hensyn til:',
        'thanks_result_page': 'Tak fordi du gennemførte testen. Er du klar til at tage udfordringen op og begynde at arbejde med at holde din hjerne sund? Du kan tilmelde dig vores hjernesunde tip med inspiration, personlige challenges, og gode råd.',
        'theme_activated': 'Denne kategori er aktiv',
        'theme_completed_with_bonus': 'Tillykke! Du har knækket <strong>alle @rewardTotalCount nødder</strong> og tjent <span class="highlighted">@bonus</span>. Du har nu gennemført alle nødder for disse kategorier.',
        'theme_completed': 'Tillykke! Du har knækket <strong>alle @rewardTotalCount nødder</strong>. Du har nu gennemført alle nødder for disse kategorier.',
        'theme_ongoing_with_bonus': 'Du har knækket <strong>@rewardCompletedCount ud af @rewardTotalCount Nødder</strong> og tjent <span class="highlighted">@bonus</span> indtil videre. Åbn en ny nød hver dag!',
        'theme_ongoing': 'Du har knækket <strong>@rewardCompletedCount ud af @rewardTotalCount Nødder</strong>. Åbn en ny nød hver dag!',
        'theme_switching_explanation': 'Har du tidligere arbejdet med en anden kategori, og vil du gerne læse disse tips igen? Det kan du gøre ved at aktivere den kategori igen.',
        'themes': 'Kategorier',
        'want_to_resume_later': 'Vil du gemme dine svar for at genoptage senere?',
        'we_hope_motivation': 'Vi håber, at du har fundet noget, der gav dig motivation til at holde godt øje med din hjernes sundhed. Du kan gå tilbage til dit resultat og beslutte, om du vil arbejde med en anden kategori de kommende uger.',
        'well_done': 'Well done!',
        'you_are_logged_out': 'Du er nu logget ud.',
        'you_can_login_using_new_password': 'Du kan nu logge ind med din nye adgangskode.',

        'snippet_nut_description': 'Din første nød vil være et spørgeskema for at tilpasse appen til dig. Følgende nødder bliver korte og nemme!',
      },
      'no': { //Norsk
        '_title': 'Norsk',
        'and': '',
        'answer': '',
        'app_name': '',
        'back': '',
        'birth_date': '',
        'bonus_plural': '',
        'bonus_singular': '',
        'change_password': '',
        'chatui_continue': '',
        'chatui_ok': '',
        'close': '',
        'completed': '',
        'connection_msg':'',
        'date_days': '@days',
        'datepicker_overlay_button': '',
        'datepicker_overlay_placeholder': '',
        'day_fri': '',
        'day_mon': '',
        'day_sat': '',
        'day_sun': '',
        'day_thu': '',
        'day_tue': '',
        'day_wed': '',
        'error_send_answer':'',
        'error_start_test':'',
        'first_reward_with_bonus': '',
        'first_reward': '',
        'form_contains_errors': '',
        'form_password_no_lower_case': '',
        'form_password_no_numeric': '',
        'form_password_no_special': '',
        'form_password_no_upper_case': '',
        'form_password_too_long': '',
        'form_password_too_short': '',
        'i_have_an_account': '',
        'information': '',
        'language': 'Språk',
        'max_selected':'',
        'min_digits': '',
        'min_lowers':'',
        'min_selected':'',
        'min_specialchars':'',
        'min_uppers':'',
        'month_apr': '',
        'month_aug': '',
        'month_dec': '',
        'month_feb': '',
        'month_full_apr': '',
        'month_full_aug': '',
        'month_full_dec': '',
        'month_full_feb': '',
        'month_full_jan': '',
        'month_full_jul': '',
        'month_full_jun': '',
        'month_full_mar': '',
        'month_full_may': '',
        'month_full_nov': '',
        'month_full_oct': '',
        'month_full_sep': '',
        'month_jan': '',
        'month_jul': '',
        'month_jun': '',
        'month_mar': '',
        'month_may': '',
        'month_nov': '',
        'month_oct': '',
        'month_sep': '',
        'navigation_error':'',
        'next': '',
        'nut_of_the_day': '',
        'password': '',
        'previous_question': '',
        'previous': '',
        'ready': '',
        'referral_list_advice': '',
        'referral_list_messgae': '',
        'referral_list_subject': '',
        'referral_list_work': '',
        'request_new_password': '',
        'retake': '',
        'see_your_results': '',
        'short_test_restrict':'',
        'stop_test': '',
        'system_notification_ignore': '',
        'system_notification_view': '',
        'test_result_good': '',
        'test_result_improve_after': '',
        'test_result_improve_plural': '',
        'test_result_improve_singular': '',
        'thanks_result_page': '',
        'theme_completed_with_bonus': '',
        'theme_completed': '',
        'theme_ongoing_with_bonus': '',
        'theme_ongoing': '',
        'themes': '',

        'snippet_nut_description': 'Din første nøtt vil være et spørreskjema for å tilpasse appen for deg. Følgende nøtter vil være korte og enkle!',
      },
      'fr': { //French
        '_title': 'Français',
        'activate_this_theme': 'Activer ce thème',
        'and': 'et',
        'answer': 'réponse',
        'app_name': 'My Brain Coach',
        'back': 'Retour',
        'birth_date': 'Date de naissance',
        'bonus_plural': '@count points bonus',
        'bonus_singular': '1 point bonus',
        'bust_nut': 'Cassez la noix',
        'cancel': 'Annuler',
        'change_password': 'Modifier le mot de passe',
        'change': 'Modifier',
        'chatui_continue': 'Continuer',
        'chatui_ok': 'Ok',
        'choose_one': '- Sélectionner -',
        'close': 'Fermer',
        'completed': 'Complété à @progress',
        'connection_msg':'Connexion perdue. MyBraincoach a besoin d\'une connexion Internet pour fonctionner correctement.',
        'contact_activities_events_consent': 'Je souhaite être contacté(e) au sujet d\'activités et d\'événements pertinents organisés par l\'équipe du <em>pdp</em> dans le domaine de la prévention et de la formation en matière de démence.',
        'contact_studies_consent': 'J\'accepte d\'être recontacté pour une éventuelle participation à des études futures.',
        'current_password': 'Mot de passe actuel',
        'daily_nuts_explanation': 'Vous continuerez à recevoir les noix quotidiennes jusqu\'à ce que vous ayez terminé tous les sujets actifs.',
        'date_days': '@days',
        'datepicker_overlay_button': 'Envoyé',
        'datepicker_overlay_placeholder': 'Entrez une année',
        'day_fri': 'Ven',
        'day_mon': 'Lun',
        'day_sat': 'Sam',
        'day_sun': 'Dim',
        'day_thu': 'Jeu',
        'day_tue': 'Mar',
        'day_wed': 'Mer',
        'email': 'Adresse électronique',
        'enter_email_and_password': 'Saisissez une adresse électronique et un mot de passe.',
        'enter_your_email': 'Saisissez votre adresse électronique.',
        'error_cannot_complete_password_request': 'La demande de mot de passe n\'a pas pu être complétée.',
        'error_email_or_password_incorrect': 'L\'adresse électronique ou le mot de passe sont incorrects.',
        'error_incorrect_temporary_password': 'Le mot de passe temporaire n\'est pas correct.',
        'error_login_failed_check_connection': 'La connexion a échoué. Veuillez vérifier la connexion Internet et réessayer.',
        'error_login_failed': 'Impossible de se connecter.',
        'error_password_requirements': 'Le mot de passe ne répond pas aux exigences.',
        'error_profile_save_failed': 'Une erreur s\'est produite lors de l\'enregistrement du profil.',
        'error_save_theme': 'Une erreur s\'est produite lors de la sélection du thème.',
        'error_send_answer':'Une erreur s\'est produite lors de l\'envoi de la réponse.',
        'error_start_test':'Une erreur s\'est produite lors du lancement du test LIBRA.',
        'female': 'Féminin',
        'first_nut_tomorrow': 'Demain, vous recevrez une nouvelle information pour en apprendre plus sur ce facteur de risque. Vous pouvez également activer plusieurs thèmes simultanément pour recevoir des notifications quotidiennes sur ces différents thèmes. Pour plus d\'informations, visitez les pages d’informations. Pour naviguer dans ces pages d’informations, cliquez sur les titres (avec un fond bleu) et découvrez les différents sujets abordés.<br /><br />Si vous utilisez un appareil mobile, veuillez-vous assurer qu\'il est configuré pour recevoir les notifications.',
        'first_reward_with_bonus': 'Vous avez cassé <strong>la première noix</strong> et gagné <span class="highlighted">@bonus</span> jusqu\'à présent. Demain, une nouvelle noix vous attend !',
        'first_reward': 'Vous venez de casser <strong>la première noix</strong>. Demain, une nouvelle noix vous attend !',
        'forgot_password': 'Mot de passe oublié ?',
        'form_contains_errors': 'Le formulaire contient les erreurs suivantes:\n',
        'form_password_no_lower_case': 'Le mot de passe ne contient pas de lettres minuscules.',
        'form_password_no_numeric': 'Le mot de passe ne contient pas de chiffres.',
        'form_password_no_special': 'Le mot de passe ne contient pas de caractères spéciaux.',
        'form_password_no_upper_case': 'Le mot de passe ne contient pas de lettres majuscules.',
        'form_password_too_long': 'Le mot de passe est trop long.',
        'form_password_too_short': 'Le mot de passe est trop court.',
        'gather_point_before': 'Rassemblez 1 point avant @date',
        'gather_points_before': 'Rassemblez @count points avant @date',
        'gender': 'Sexe',
        'go_to_themes': 'Aller aux thèmes',
        'good_job': 'Excellent travail !',
        'i_am_pdp_participant': 'Je suis déjà un participant <em>pdp</em> (<a href="https://pdp.lu/fr/" target="_blank">Programme Démence Prévention</a>)',
        'i_have_an_account': 'J\'ai déjà un compte',
        'i_will_participate': 'Je participe',
        'information': 'Informations',
        'join': 'Je participe',
        'language': 'Langue',
        'lets_get_started': 'Commençons',
        'login': 'Se connecter',
        'logo_alt': 'Logo My Brain Coach',
        'logout': 'Se déconnecter',
        'make_free_account': 'Gardez votre cerveau en forme ! Créez votre propre compte maintenant et obtenez un accès gratuit à l\'application <em>pdp</em> Braincoach.',
        'male': 'Masculin',
        'max_selected':'Sélectionnez jusqu\'à :max_selected options dans le champ :attribute.',
        'min_digits': 'Le champ :attribute doit contenir au moins le(s) nombre(s) :min_digits.',
        'min_lowers':'Le champ :attribute doit contenir au moins :min_lowers lettre(s) minuscule(s).',
        'min_selected':'Sélectionnez au moins :min_selected options dans le champ :attribute.',
        'min_specialchars':'Le champ :attribute doit contenir au moins le(s) caractère(s) spécial(s) :min_specialchars.',
        'min_uppers': 'Le champ :attribute doit contenir au moins :min_uppers lettre(s) majuscule(s).',
        'month_apr': 'avr',
        'month_aug': 'août',
        'month_dec': 'déc',
        'month_feb': 'fév',
        'month_full_apr': 'avril',
        'month_full_aug': 'août',
        'month_full_dec': 'décembre',
        'month_full_feb': 'février',
        'month_full_jan': 'janvier',
        'month_full_jul': 'juillet',
        'month_full_jun': 'juin',
        'month_full_mar': 'mars',
        'month_full_may': 'mai',
        'month_full_nov': 'novembre',
        'month_full_oct': 'octobre',
        'month_full_sep': 'septembre',
        'month_jan': 'jan',
        'month_jul': 'jul',
        'month_jun': 'juin',
        'month_mar': 'mar',
        'month_may': 'mai',
        'month_nov': 'nov',
        'month_oct': 'oct',
        'month_sep': 'sep',
        'more_info': 'Plus d\'informations',
        'name': 'Nom',
        'navigation_error':'Une erreur s\'est produite lors de la navigation.',
        'new_password_confirmation': 'Nouveau mot de passe (confirmation)',
        'new_password': 'Nouveau mot de passe',
        'next': 'Suivant',
        'no': 'Non',
        'nut_of_the_day': 'Noix du jour',
        'nut_rewards_alt': 'Les noix cassées',
        'other': 'Autre',
        'password_confirmation': 'Mot de passe (confirmation)',
        'password_request_sent': 'La demande de mot de passe a été envoyée à votre adresse e-mail.',
        'password_requirements_hint': 'Un mot de passe sécurisé est composé d\'au moins 8 caractères, dont au moins 1 chiffre, 1 lettre minuscule, 1 lettre majuscule et 1 signe de ponctuation.',
        'password': 'Mot de passe',
        'phone_number': 'Numéro de téléphone',
        'postal_code_only_digits': 'Saisissez uniquement les quatre chiffres.',
        'postal_code': 'Code postal',
        'previous_question': 'Question précédente',
        'previous': 'Précédent',
        'privacy_consent_explanation': ' pour le participant de <em>pdp</em> Braincoach, y compris le traitement de mes données personnelles pour l\'utilisation de l\'application <em>pdp</em> Braincoach (*).',
        'privacy_consent': 'J\'ai lu et j\'accepte ',
        'profile': 'Profil',
        'quit': 'Quitter',
        'read_privacy_statement': 'la feuille d\'information (politique de confidentialité incluse) et de consentement éclairé électronique',
        'ready': 'Prêt !',
        'referral_list_advice': 'Vous recevez des conseils personnalisés',
        'referral_list_messgae': 'Vous recevrez un court message chaque jour : un "fait", une question de quiz, un conseil ou un défi.',
        'referral_list_subject': 'Vous choisissez où, quand et sur quel thème travailler.',
        'referral_list_work': 'Vous gardez votre cerveau en forme de manière amusante.',
        'region': 'Région',
        'register_for_more': 'Vous voulez commencer à travailler sur vos points d\'amélioration de manière ludique et connaître votre score sur tous les thèmes ? Créez un compte maintenant !',
        'request_new_password': 'Demander un nouveau mot de passe',
        'reset_password_instructions': 'Définissez votre nouveau mot de passe. Saisissez le mot de passe temporaire que vous avez reçu par e-mail ci-dessous et saisissez un nouveau mot de passe.',
        'reset_password': 'Réinitialiser le mot de passe',
        'resume_later': 'Oui, reprendre plus tard',
        'retake': 'Répéter le test',
        'rewards_explanation': 'Merci d\'avoir rempli le formulaire. L\'étape suivante consiste à examiner les sujets qui peuvent être améliorés. Sélectionnez un sujet sur lequel vous voulez travailler et vous recevrez chaque jour de nouveaux conseils, une nouvelle question ou une information utile sur ce thème.',
        'save': 'Sauvegarder',
        'see_results': 'Consultez vos résultats et activez le thème sur lequel vous voulez travailler.',
        'see_your_results': 'Prêt',
        'short_test_restrict':'Le test LIBRA court n\'est pas disponible lorsque vous êtes connectés',
        'start_app': 'Démarrer My Brain Coach',
        'stop_test': 'Quitter le test',
        'subscribe_to_newsletter_link': 'S\'abonner à la newsletter',
        'subscribe_to_newsletter': 'Je m\'inscris à la newsletter',
        'system_notification_ignore': 'Ignorer',
        'system_notification_view': 'Voir',
        'temp_password': 'Mot de passe temporaire',
        'test_result_good': 'Votre test court montre que vous vous débrouillez bien. Continuez comme ça !',
        'test_result_improve_after': '',
        'test_result_improve_plural': 'Votre test court montre que vous pouvez améliorer les domaines suivants: ',
        'test_result_improve_singular': 'Votre test court montre que vous pouvez encore progresser dans le domaine de ',
        'test_result_keep_up': ', continuez comme ça !',
        'test_result_maintain': 'En vert, vous pouvez voir les points sur lesquels vous avez obtenu de bons résultats, à savoir',
        'thanks_result_page': 'Merci d\'avoir complété le test rapide ! Voulez-vous améliorer votre score de manière amusante ? Alors rendez-vous sur mijnbraincoach.eu ou téléchargez l\'application.',
        'theme_activated': 'Ce thème est maintenant actif',
        'theme_completed_with_bonus': 'Félicitations ! Vous avez ouvert <strong>toutes les @rewardTotalCount noix</strong> sur ce thème et gagné <span class="highlighted">@bonus</span>.',
        'theme_completed': 'Félicitations ! Vous avez cassé <strong>toutes les @rewardTotalCount noix</strong>. Vous avez maintenant terminé toutes les noix sur ce thème.',
        'theme_ongoing_with_bonus': 'Vous avez déjà ouvert <strong>@rewardCompletedCount des @rewardTotalCount noix</strong> et gagné <span class="highlighted">@bonus</span> (en ayant répondu à un quiz ou à une question de défi). Ouvrez une nouvelle noix chaque jour !',
        'theme_ongoing': 'Vous avez déjà cassé <strong>@rewardCompletedCount des @rewardTotalCount noix</strong>. Ouvrez une nouvelle noix chaque jour !',
        'theme_switching_explanation': 'Vous avez déjà travaillé sur un autre thème et vous aimeriez relire les conseils donnés ? Vous pouvez le faire en activant à nouveau ce thème.',
        'themes': 'Thèmes',
        'want_to_resume_later': 'Voulez-vous enregistrer vos réponses pour pouvoir reprendre plus tard ?',
        'we_hope_motivation': 'Nous espérons avoir renforcé votre motivation à surveiller de près la santé de votre cerveau. Si vous le souhaitez, vous pouvez retourner à votre feuille de résultats et décider si vous voulez travailler sur un autre thème dans les semaines à venir.',
        'well_done': 'Bien joué!',
        'you_are_logged_out': 'Vous êtes maintenant déconnecté.',
        'you_can_login_using_new_password': 'Vous pouvez maintenant vous connecter avec votre nouveau mot de passe.',

        'snippet_nut_description': 'Votre premier écrou sera un questionnaire pour personnaliser l\'application pour vous. Les noix suivantes seront courtes et faciles!',
      },
      'de': { //German
        '_title': 'Deutsch',
        'activate_this_theme': 'Dieses Thema aktivieren',
        'and': 'und',
        'answer': 'Antwort',
        'app_name': 'My Brain Coach',
        'back': 'Zurück',
        'birth_date': 'Geburtsdatum',
        'bonus_plural': '@count Bonuspunkte',
        'bonus_singular': '1 Bonuspunkt',
        'bust_nut': 'Knacken Sie die Nuss',
        'cancel': 'Abbrechen',
        'change_password': 'Passwort ändern',
        'change': 'Ändern',
        'chatui_continue': 'Weiter',
        'chatui_ok': 'Ok',
        'choose_one': '- Auswählen -',
        'close': 'Schließen',
        'completed': '@progress abgeschlossen',
        'connection_msg':'Verbindung verloren. My Brain Coach benötigt eine Internetverbindung, um richtig zu funktionieren.',
        'contact_activities_events_consent': 'Ich möchte über relevante Aktivitäten und Veranstaltungen des <em>pdp</em> Teams, die auf Demenzprävention und -schulung ausgerichtet sind, informiert werden.',
        'contact_studies_consent': 'Ich bin damit einverstanden, für eine mögliche Teilnahme an zukünftigen Studien kontaktiert zu werden.',
        'current_password': 'Aktuelles Passwort',
        'daily_nuts_explanation': 'Sie erhalten weiterhin die täglichen Nüsse, bis Sie alle aktiven Themen abgeschlossen haben.',
        'date_days': '@days',
        'datepicker_overlay_button': 'Gesendet',
        'datepicker_overlay_placeholder': 'Geben Sie ein Jahr ein',
        'day_fri': 'Fr',
        'day_mon': 'Mo',
        'day_sat': 'Sa',
        'day_sun': 'So',
        'day_thu': 'Do',
        'day_tue': 'Di',
        'day_wed': 'Mi',
        'email': 'E-Mail Adresse',
        'enter_email_and_password': 'Geben Sie eine E-Mail-Adresse und ein Passwort ein.',
        'enter_your_email': 'Geben Sie Ihre E-Mail-Adresse ein.',
        'error_cannot_complete_password_request': 'Die Passwortanforderung konnte nicht abgeschlossen werden.',
        'error_email_or_password_incorrect': 'Die E-Mail-Adresse oder das Passwort sind falsch.',
        'error_incorrect_temporary_password': 'Das temporäre Passwort ist nicht korrekt.',
        'error_login_failed_check_connection': 'Anmeldung fehlgeschlagen. Bitte überprüfen Sie die Internetverbindung und versuchen Sie es erneut.',
        'error_login_failed': 'Sie können sich nicht anmelden.',
        'error_password_requirements': 'Das Passwort entspricht nicht den Anforderungen.',
        'error_profile_save_failed': 'Beim Speichern des Profils ist ein Fehler aufgetreten.',
        'error_save_theme': 'Beim Einstellen des Themas ist ein Fehler aufgetreten.',
        'error_send_answer':'Beim Senden der Antwort ist ein Fehler aufgetreten.',
        'error_start_test':'Beim Starten des LIBRA-Tests ist ein Fehler aufgetreten.',
        'female': 'Weiblich',
        'first_nut_tomorrow': 'Morgen werden Sie eine neue Nuss erhalten, um mehr über diesen Risikofaktor zu erfahren. Sie können aber auch mehrere Themen gleichzeitig aktivieren, um täglich eine Benachrichtigung zu einem dieser Themen zu erhalten. Weitere Informationen finden Sie auf den Informationsseiten. Klicken Sie auf einen Titel (mit blauem Hintergrund), um alle Themen zu sehen.<br /><br />Wenn Sie ein mobiles Gerät benutzen, stellen Sie bitte sicher, dass es so eingestellt ist, dass Sie Benachrichtigungen erhalten.',
        'first_reward_with_bonus': 'Sie haben jetzt <strong>die erste Nuss</strong> geknackt und bis jetzt <span class="highlighted">@bonus</span> verdient. Morgen wartet eine neue Nuss auf Sie!',
        'first_reward': 'Sie haben jetzt <strong>die erste Nuss</strong> geknackt. Morgen wartet eine neue Nuss auf Sie!',
        'forgot_password': 'Passwort vergessen?',
        'form_contains_errors': 'Das Formular enthält die folgenden Fehler:\n',
        'form_password_no_lower_case': 'Das Passwort enthält keine Kleinbuchstaben.',
        'form_password_no_numeric': 'Das Passwort enthält keine Zahlen.',
        'form_password_no_special': 'Das Passwort enthält keine Sonderzeichen.',
        'form_password_no_upper_case': 'Das Passwort enthält keine Großbuchstaben.',
        'form_password_too_long': 'Das Passwort ist zu lang.',
        'form_password_too_short': 'Das Passwort ist zu kurz.',
        'gather_point_before': 'Sammle 1 Punkt vor @date',
        'gather_points_before': 'Sammle @count Punkte vor @date',
        'gender': 'Geschlecht',
        'go_to_themes': 'Zu den Themen',
        'good_job': 'Gut gemacht!',
        'i_am_pdp_participant': 'Ich bin bereits <em>pdp</em>-Teilnehmer (<a href="https://pdp.lu/de/" target="_blank">Programm zur Demenzprävention</a>)',
        'i_have_an_account': 'Ich habe bereits ein Konto',
        'i_will_participate': 'Ich nehme teil',
        'information': 'Informationen',
        'join': 'Ich nehme teil',
        'language': 'Sprache',
        'lets_get_started': 'Legen Sie los',
        'login': 'Anmeldung',
        'logo_alt': 'Logo My Brain Coach',
        'logout': 'Abmelden',
        'make_free_account': 'Setzen Sie sich für ein gesundes Gehirn ein! Erstellen Sie jetzt Ihr eigenes Konto und erhalten Sie kostenlosen Zugang zur <em>pdp</em> Braincoach app.',
        'male': 'Männlich',
        'max_selected':'Wählen Sie bis zu :max_selected Optionen im Feld :attribute.',
        'min_digits': 'Das Feld :attribute muss mindestens :min_digits Zahl(en) enthalten.',
        'min_lowers':'Das Feld :attribute muss mindestens :min_lowers Kleinbuchstaben enthalten.',
        'min_selected':'Wählen Sie mindestens :min_selected Optionen im Feld :attribute.',
        'min_specialchars':'Das Feld :attribute muss mindestens :min_specialchars Sonderzeichen enthalten.',
        'min_uppers': 'Das Feld :attribute muss mindestens :min_uppers Großbuchstabe(n) enthalten',
        'month_apr': 'Apr',
        'month_aug': 'Aug',
        'month_dec': 'Dez',
        'month_feb': 'Feb',
        'month_full_apr': 'April',
        'month_full_aug': 'August',
        'month_full_dec': 'Dezember',
        'month_full_feb': 'Februar',
        'month_full_jan': 'Januar',
        'month_full_jul': 'Juli',
        'month_full_jun': 'Juni',
        'month_full_mar': 'März',
        'month_full_may': 'Mai',
        'month_full_nov': 'November',
        'month_full_oct': 'Oktober',
        'month_full_sep': 'September',
        'month_jan': 'Jan',
        'month_jul': 'Jul',
        'month_jun': 'Jun',
        'month_mar': 'März',
        'month_may': 'Mai',
        'month_nov': 'Nov',
        'month_oct': 'Okt',
        'month_sep': 'Sep',
        'more_info': 'Mehr Info',
        'name': 'Name',
        'navigation_error':'Beim Navigieren ist ein Fehler aufgetreten.',
        'new_password_confirmation': 'Neues Passwort (Bestätigung)',
        'new_password': 'Neues Passwort',
        'next': 'Weiter',
        'no': 'Nein',
        'nut_of_the_day': 'Nuss des Tages',
        'nut_rewards_alt': 'Geknackte Nüsse',
        'other': 'Andere',
        'password_confirmation': 'Passwort (Bestätigung)',
        'password_request_sent': 'Die Passwortanforderung wurde an Ihre E-Mail-Adresse gesendet.',
        'password_requirements_hint': 'Ein sicheres Passwort besteht aus mindestens 8 Zeichen, davon mindestens 1 Zahl, 1 Kleinbuchstabe, 1 Großbuchstabe und 1 Sonderzeichen.',
        'password': 'Passwort',
        'phone_number': 'Telefonnummer',
        'postal_code_only_digits': 'Geben Sie nur die vier Ziffern ein.',
        'postal_code': 'Postleitzahl',
        'previous_question': 'Vorherige Frage',
        'previous': 'Zurück',
        'privacy_consent_explanation': ' gelesen und stimme ihnen zu, einschließlich der Verarbeitung meiner persönlichen Daten zur Nutzung der <em>pdp</em> Braincoach App (*).',
        'privacy_consent': 'Ich habe das <em>pdp</em> Braincoach ',
        'profile': 'Profil',
        'quit': 'Verlassen',
        'read_privacy_statement': 'Benutzerinformationsblatt (einschließlich Datenschutzrichtlinie) für den/die Teilnehmer/in und elektronisches Formular für die Einverständniserklärung',
        'ready': 'Fertig!',
        'referral_list_advice': 'Sie erhalten eine persönliche Beratung',
        'referral_list_messgae': 'Sie erhalten jeden Tag eine kurze Nachricht: einen "Fakt", eine Quizfrage, einen Tipp oder eine Herausforderung',
        'referral_list_subject': 'Sie wählen, wo, wann und mit welchem Thema Sie arbeiten wollen',
        'referral_list_work': 'Sie arbeiten auf spielerische Art und Weise an einem gesunden(eren) Gehirn',
        'region': 'Kanton',
        'register_for_more': 'Möchten Sie auf spielerische Weise lernen, Punkte sammeln und wissen, wie Sie bei allen Themen abschneiden? Erstellen Sie jetzt ein Konto!',
        'request_new_password': 'Neues Passwort anfordern',
        'reset_password_instructions': 'Legen Sie Ihr neues Passwort fest. Geben Sie das vorläufige Passwort ein, das Sie per E-Mail erhalten haben, und geben Sie ein neues Passwort ein.',
        'reset_password': 'Passwort zurücksetzen',
        'resume_later': 'Ja, später fortsetzen',
        'retake': 'Test wiederholen',
        'rewards_explanation': 'Danke, dass Sie das Formular ausgefüllt haben. Der nächste Schritt besteht darin, sich die Themen anzuschauen, bei denen noch Verbesserungsbedarf besteht. Wählen Sie ein Thema aus, an dem Sie arbeiten möchten, und Sie erhalten dazu jeden Tag einen neuen Tipp, eine Frage oder einen nützlichen Fakt zu diesem Thema.',
        'save': 'Speichern',
        'see_results': 'Sehen Sie sich Ihre Ergebnisse an und aktivieren Sie das Thema, an dem Sie arbeiten möchten.',
        'see_your_results': 'Bereit',
        'short_test_restrict':'Der LIBRA-Kurztest ist nicht verfügbar, wenn Sie eingeloggt sind.',
        'start_app': 'My Brain Coach starten',
        'stop_test': 'Den Test beenden',
        'subscribe_to_newsletter_link': 'Abonnieren Sie den Newsletter',
        'subscribe_to_newsletter': 'Melden Sie mich für den Newsletter an',
        'system_notification_ignore': 'Ignorieren',
        'system_notification_view': 'Ansicht',
        'temp_password': 'Temporäres Passwort',
        'test_result_good': 'Der kurze Test zeigt, dass Sie gut abschneiden. Weiter so!',
        'test_result_improve_after': ' Verbesserungsmöglichkeiten gibt',
        'test_result_improve_plural': 'Der kurze Test zeigt, dass es in den Bereichen ',
        'test_result_improve_singular': 'Der kurze Test zeigt, dass es in folgenden Bereichen Verbesserungspotenzial gibt: ',
        'test_result_keep_up': ', weiter so!',
        'test_result_maintain': 'In Grün sehen Sie, wo Sie gut abgeschnitten haben, nämlich bei',
        'thanks_result_page': 'Danke, dass Sie den Schnelltest gemacht haben! Möchten Sie auf spielerische Art und Weise mit Ihren Verbesserungsmöglichkeiten beginnen? Dann gehen Sie auf mijnbraincoach.eu oder laden Sie die App herunter.',
        'theme_activated': 'Dieses Thema ist jetzt aktiv',
        'theme_completed_with_bonus': 'Herzlichen Glückwunsch! Sie haben <strong>alle @rewardTotalCount Nüsse</strong> zu diesem Thema geknackt und <span class="highlighted">@bonus</span> verdient.',
        'theme_completed': 'Herzlichen Glückwunsch! Sie haben <strong>alle @rewardTotalCount Nüsse</strong> geknackt. Sie haben nun alle Nüsse zu diesem Thema geknackt.</p>',
        'theme_ongoing_with_bonus': 'Sie haben schon <strong>@rewardCompletedCount von @rewardTotalCount Nüssen</strong> geknackt und <span class="highlighted">@bonus</span> (durch das Beantworten eines Quiz oder einer Challenge-Frage) verdient. Öffnen Sie jeden Tag eine neue Nuss!',
        'theme_ongoing': 'Sie haben schon <strong>@rewardCompletedCount von @rewardTotalCount Nüssen</strong> geknackt. Öffnen Sie jeden Tag eine neue Nuss!',
        'theme_switching_explanation': 'Haben Sie bereits an einem anderen Thema gearbeitet und möchten Sie diese Nüsse noch einmal lesen? Das können Sie tun, indem Sie das Thema erneut aktivieren.',
        'themes': 'Themen',
        'want_to_resume_later': 'Möchten Sie Ihre Antworten speichern, um später fortzufahren?',
        'we_hope_motivation': 'Wir hoffen, dass Sie etwas gefunden haben, das Sie motiviert hat, Ihre Gehirn-Fitness im Auge zu behalten. Wenn Sie möchten, können Sie zu Ihrem Bewertungsbogen zurückkehren und entscheiden, ob Sie sich in den kommenden Wochen mit einem anderen Thema beschäftigen möchten.',
        'well_done': 'Gut gemacht!',
        'you_are_logged_out': 'Sie sind jetzt abgemeldet.',
        'you_can_login_using_new_password': 'Sie können sich jetzt mit Ihrem neuen Passwort anmelden.',

        'snippet_nut_description': 'Ihre erste Nuss wird ein Fragebogen sein, um die App für Sie zu personalisieren. Die folgenden Nüsse werden kurz und einfach sein!',
      }
    };

    this.enabledLanguages = [
      'en',
      'fr',
      'de',
    ];

    this.language = App.intLang;
  }

  getLanguages () {
    let languages = {};
    for (let i in this.enabledLanguages) {
      let lang = this.enabledLanguages[i];
      languages[lang] = this.strings[lang]['_title'];
    }
    return languages;
  }

  isValidLanguage (code) {
    for (let i in this.enabledLanguages) {
      let lang = this.enabledLanguages[i];
      if (code === lang) {
        return true;
      }
    }
    return false;
  }

  loadFromData(data) {
    this.strings = data;
  }

  getLanguage () {
    return this.language;
  }

  setLanguage(lang) {
    this.language = lang;
    Validator.useLang(this.language);
  }

  get(key, options) {
    if (!this.strings[this.language]) {
      return '{' + this.language + ':' + key + '}';
    }

    let trans = this.strings[this.language][key];
    if (trans === undefined) {
      return '{' + this.language + ':' + key + '}';
    }

    for (let option in options) {
      trans = trans.replace(new RegExp('@' + option, 'g'), options[option]);
    }

    return trans;
  }

  // This method is primarily for unauthenticated users.
  getPreferredLanguage() {
    let i18n = this;

    let preferredLanguage = window.localStorage.getItem('preferredLanguage');
    if (preferredLanguage !== null) {
      return Promise.resolve(preferredLanguage);
    }

    if (navigator.globalization === undefined) {
      // Try using navigator.languages.
      if (navigator.languages !== null) {
        for (let i in navigator.languages) {
          let sub = navigator.languages[i].substr(0, 2);
          if (this.isValidLanguage(sub)) {
            return Promise.resolve(sub);
          }
        }
      }

      console.warn('No globalization support. Using ' + i18n.language + ' as preferred language.');
      return Promise.resolve(i18n.language);
    }

    return new Promise(function(resolve, reject) {
      navigator.globalization.getPreferredLanguage(
        function (language) {
          let lang = i18n.language;
          let sub = language.value.substr(0, 2);

          if (this.isValidLanguage(sub)) {
            lang = sub;
          } else {
            console.warn('Unknown preferred language ' + language.value + '. Using ' + i18n.language + ' as preferred language.');
          }

          resolve(lang);
        },
        function() {
          console.warn('Could not get preferred language. Using ' + i18n.language + ' as preferred language.');
          resolve(i18n.language);
        }
      );
    });
  }

  setPreferredLanguage (code) {
    window.localStorage.setItem('preferredLanguage', code);
  }

  checkIntegrity() {
    // Determine union of all language keys.
    let allKeys = [];
    for (let lang in this.strings) {
      for (let key in this.strings[lang]) {
        if (!allKeys.includes(key)) {
          allKeys.push(key);
        }
      }
    }

    // Now check which language is missing keys.
    for (let lang in this.strings) {
      let keyCount = 0;
      for (let key in this.strings[lang]) {
        keyCount++;
      }
      console.log('language: ' + lang + ', ' + keyCount + ' strings');

      let missingKeys = [];
      for (let i in allKeys) {
        let key = allKeys[i];
        if (typeof this.strings[lang][key] === 'undefined' || this.strings[lang][key] === '') {
          missingKeys.push(key);
        }
      }

      console.log('missing keys: ' + missingKeys.join(', '));
    }
  }
}

export default new I18n();
