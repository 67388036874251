'use strict';

import I18n from "./i18n";

export default {
  getFormValues: function (form) {
    let arr = form.serializeArray();
    let values = {};

    for (let i in arr) {
      values[arr[i].name] = arr[i].value;
    }

    return values;
  },

  getFormDisplayableErrors: function (errors) {
    let str = I18n.get('form_contains_errors');
    let errorList = errors.all();
    for (let i in errorList) {
      for (let j in errorList[i]) {
        str += '- ' + errorList[i][j] + '\n';
      }
    }

    return str;
  },

  getErrorResponseDisplayableErrors: function (response) {
    let errors = this.parseErrorResponse(response);
    if (errors.length > 0) {
      let str = I18n.get('form_contains_errors');
      for (let i in errors) {
        str += '- ' + errors[i].error + '\n';
      }
      return str;
    }
    return null;
  },

  parseErrorResponse(response) {
    if (!response.message) {
      return [];
    }

    let errors = [];
    if (typeof response.message === 'string') {
      let parts = response.message.split('\n');

      for (let index = 0; index < parts.length; index++) {
        let part = parts[index];

        // Extract field name from string: field_name.0.target_id: error
        let cindex = part.indexOf(':');
        if (cindex < 0) {
          continue;
        }
        let fieldName = part.substr(0, cindex);

        let dindex = fieldName.indexOf('.');
        if (dindex >= 0) {
          fieldName = fieldName.substr(0, dindex);
        }
        if (fieldName === 'Unprocessable Entity') {
          continue;
        }

        let fieldDesc = part.substr(cindex + 2);
        errors.push({
          field: fieldName,
          error: fieldDesc
        });
      }
    } else if (typeof response.message === 'object') {
      for (let index in response.message) {
        if (response.message.hasOwnProperty(index)) {
          errors.push({
            field: I18n.get('password'),
            error: I18n.get('form_' + response.message[index])
          });
        }
      }
    }

    return errors;
  }

};
