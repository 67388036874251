'use strict';

import jQuery from 'jquery';

function clickMultiSelectOption () {
  let el = jQuery(this);
  let container = el.closest('[data-none-above-value]');
  if (el.val() === container.data('none-above-value') && el.prop('checked')) {
    // Uncheck other options.
    container.find('input[type="checkbox"]').not(el)
      .prop("checked", false);
  } else if (el.val() !== container.data('none-above-value')) {
    // Uncheck none option.
    container.find('input[type="checkbox"]').not(el).filter(function () {
      return jQuery(this).val() === container.data('none-above-value');
    }).prop("checked", false);
  }
}

export function attachElement (element) {
  // Rangeslider.
  element.find('input[type="range"]').rangeslider({
    rangeClass: 'rangeslider',
    polyfill: false,
    onSlide: function(position, value) {
      element.find('.range-output').text(value);
    }
  });

  // 'None of the above' multiSelect elements.
  element.find('[data-none-above-value]').on('click', 'input[type="checkbox"]', clickMultiSelectOption);

  // External links.
  element.find('a:not([data-ext-link])').each(function () {
    jQuery(this)
      .attr('target', '_blank')
      .attr('rel', 'noopener noreferrer')
      .data('ext-link', 1);
  });
}
