'use strict';

import Validator from 'validatorjs';
import ValidatorLangNl from './validatorjs/nl';
import ValidatorLangEn from './validatorjs/en';
import ValidatorLangNo from './validatorjs/no';
import ValidatorLangDa from './validatorjs/da';
import ValidatorLangFr from './validatorjs/fr';
import ValidatorLangDe from './validatorjs/de';
import App from '../app';
import I18n from "./i18n";

export default function () {
  // Set language specific validation messages
  Validator.setMessages('nl', ValidatorLangNl);
  Validator.setMessages('en', ValidatorLangEn);
  Validator.setMessages('no', ValidatorLangNo);
  Validator.setMessages('da', ValidatorLangDa);
  Validator.setMessages('fr', ValidatorLangFr);
  Validator.setMessages('de', ValidatorLangDe);
  Validator.useLang(App.intLang);

  // Register custom form validators

  Validator.register('min_digits', function (value, requirement, attribute) {
    let digitsFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= '0' && ch <= '9') {
        digitsFound++;
      }
    }
    return digitsFound >= parseInt(requirement, 10);
  }, I18n.get('min_digits'));

  Validator.register('min_lowers', function (value, requirement, attribute) {
    let lowersFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= 'a' && ch <= 'z') {
        lowersFound++;
      }
    }
    return lowersFound >= parseInt(requirement, 10);
  }, I18n.get('min_lowers'));

  Validator.register('min_uppers', function (value, requirement, attribute) {
    let uppersFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if (ch >= 'A' && ch <= 'Z') {
        uppersFound++;
      }
    }
    return uppersFound >= parseInt(requirement, 10);
  }, I18n.get('min_uppers'));

  Validator.register('min_specialchars', function (value, requirement, attribute) {
    let specialsFound = 0;
    let ch;
    for (let i = 0; i < value.length; i++) {
      ch = value.charAt(i);
      if ((ch >= '!' && ch <= '/') || (ch >= ':' && ch <= '@') || (ch >= '[' && ch <= '`')) {
        specialsFound++;
      }
    }
    return specialsFound >= parseInt(requirement, 10);
  }, I18n.get('min_specialchars'));

  Validator.register('min_selected', function (value, requirement, attribute) {
    var values = value.split('|');
    return values.length >= parseInt(requirement, 10);
  }, I18n.get('min_selected'));

  Validator.register('max_selected', function (value, requirement, attribute) {
    var values = value.split('|');
    return values.length <= parseInt(requirement, 10);
  }, I18n.get('max_selected'));

  return Validator;
}
