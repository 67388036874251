module.exports = {
  accepted: 'Das Feld :attribute muss akzeptiert werden.',
  after: ':attribute muss ein Datum nach :after sein.',
  after_or_equal: 'Das :attribute Datum muss am oder nach :after_or_equal liegen.',
  alpha: 'Das Feld :attribute darf nur Buchstaben enthalten.',
  alpha_dash: 'Das Feld :attribute darf nur Buchstaben, Zahlen und (unterstrichene) Bindestriche enthalten.',
  alpha_num: 'Das Feld :attribute darf nur Buchstaben und Zahlen enthalten.',
  before: ':attribute muss vor :before stehen.',
  before_or_equal: ':attribute muss vor oder auf :before_or_equal stehen.',
  between: 'Das :attribute Feld muss zwischen :min und :max liegen.',
  confirmed: 'Das Feld :attribute stimmt nicht mit der Bestätigung überein.',
  email: 'Das :attribute Format ist ungültig.',
  date: 'Das Feld :attribute muss ein gültiges Datum sein.',
  def: 'Das Feld :attribute enthält Fehler.',
  digits: 'Das :attribute-Feld muss :digits Zahlen enthalten.',
  different: 'Das :attribute und das :different Feld müssen unterschiedlich sein.',
  'in': 'Der ausgewählte Wert für :attribute ist ungültig.',
  integer: 'Das Feld :attribute muss eine Ganzzahl sein.',
  min: {
    numeric: 'Das :attribute Feld muss mindestens :min sein.',
    string: 'Das :attribute Feld muss mindestens :min Zeichen enthalten.'
  },
  max: {
    numeric: 'Das :attribute Feld darf maximal :max groß sein.',
    string: 'Das :attribute Feld darf nicht mehr als :max Zeichen enthalten.'
  },
  not_in: 'Der ausgewählte Wert für :attribute ist ungültig.',
  numeric: 'Das :attribute Feld muss eine Zahl sein.',
  present: 'Das Feld :attribute muss vorhanden sein (kann aber leer sein).',
  required: 'Das Feld :attribute muss ausgefüllt werden.',
  required_if: 'Das Feld :attribute muss ausgefüllt werden, wenn :other den Wert :value hat.',
  required_unless: 'Das Feld „:attribute“ muss ausgefüllt werden, wenn „:other“ nicht „:value“ ist.',
  required_with: 'Das :attribute Feld muss ausgefüllt werden, wenn :field nicht leer ist.',
  required_with_all: 'Das :attribute Feld muss ausgefüllt werden, wenn :fields nicht leer sind.',
  required_without: 'Das :attribute Feld muss ausgefüllt werden, wenn :field leer ist.',
  required_without_all: 'Das :attribute Feld muss ausgefüllt werden, wenn :fields leer sind.',
  same: 'Die Felder :attribute und :same müssen übereinstimmen.',
  size: {
    numeric: 'Das :attribute Feld muss :size sein.',
    string: 'Das :attribute Feld muss :size Zeichen enthalten.'
  },
  string: 'Das :attribute Feld muss ein Wort oder eine Phrase sein.',
  url: 'Das Feld :attribute hat ein ungültiges Format.',
  regex: 'Das Feld :attribute hat ein ungültiges Format.',

  attributes: {}

};
