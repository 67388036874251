'use strict';

import Marionette from 'backbone.marionette';

import * as Templates from '../templates';

import '../partials';


Marionette.TemplateCache.prototype.loadTemplate = function(templateId) {
  return Templates[templateId];
};

Marionette.TemplateCache.prototype.compileTemplate = function(rawTemplate) {
  return rawTemplate;
};
