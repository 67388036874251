module.exports = {
  accepted: 'Le champ :attribute doit être accepté.',
  after: ':attribute doit être une date après :after.',
  after_or_equal: 'La date :attribute doit être le ou après :after_or_equal',
  alpha: 'Le champ :attribute ne peut contenir que des lettres.',
  alpha_dash: 'Le champ :attribute ne peut contenir que des lettres, des chiffres et des tirets (soulignés).',
  alpha_num: 'Le champ :attribute ne peut contenir que des lettres et des chiffres.',
  before: ':attribute doit être avant :before.',
  before_or_equal: ':attribute doit être avant ou sur :before_or_equal.',
  between: 'Le champ :attribute doit être compris entre :min et :max.',
  confirmed: 'Le champ :attribute ne correspond pas à la confirmation.',
  email: 'Le format :attribute n\'est pas valide.',
  date: 'Le champ :attribute doit être une date valide.',
  def: 'Le champ :attribute contient des erreurs.',
  digits: 'Le champ :attribute doit contenir des :digits numéros',
  different: 'Les champs :attribute et :different doivent être différents.',
  'in': 'La valeur choisie pour :attribute n\'est pas valide.',
  integer: 'Le champ :attribute doit être un entier.',
  min: {
    numeric: 'Le champ :attribute doit être au moins :min.',
    string: 'Le champ :attribute doit contenir au moins :min caractères.'
  },
  max: {
    numeric: 'Le champ :attribute peut contenir au maximum :max.',
    string: 'Le champ :attribute ne peut pas contenir plus de :max caractères.'
  },
  not_in: 'La valeur choisie pour :attribute n\'est pas valide.',
  numeric: 'Le champ :attribute doit être un nombre.',
  present: 'Le champ :attribute doit être présent (mais peut être vide)',
  required: 'Le champ :attribute doit être renseigné.',
  required_if: 'Le champ :attribute doit être renseigné lorsque :other est :value.',
  required_unless: 'Le champ :attribute doit être renseigné lorsque :other n\'est pas :value.',
  required_with: 'Le champ :attribute doit être renseigné lorsque :field n\'est pas vide.',
  required_with_all: 'Le champ :attribute doit être renseigné lorsque les :fields ne sont pas vides.',
  required_without: 'Le champ :attribute doit être renseigné lorsque :field est vide.',
  required_without_all: 'Le champ :attribute doit être renseigné lorsque les :fields sont vides.',
  same: 'Les champs :attribute et :same doivent correspondre.',
  size: {
    numeric: 'Le champ :attribute doit être :size.',
    string: 'Le champ :attribute doit contenir les caractères :size.'
  },
  string: 'Le champ :attribute doit être un mot ou une expression.',
  url: 'Le champ :attribute est dans un format non valide.',
  regex: 'Le champ :attribute est dans un format non valide.',

  attributes: {}

};
