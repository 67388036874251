'use strict';

import Marionette from 'backbone.marionette';

import HeaderView from '../views/header';

import AnimatedRegion from '../utils/animatedregion';

export default Marionette.View.extend({
  template: 'root',

  tagName: 'div',
  id: 'container',

  regions: {
    header: '.region-header',
    content: {
      regionClass: AnimatedRegion,
      selector: '.region-content'
    }
  },

  onRender: function() {
    this.getRegion('header').show(new HeaderView());
  }
});