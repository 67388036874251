'use strict';

import Backbone from 'backbone';

import ThemeProgress from '../models/theme_progress';

export default Backbone.Collection.extend({
  model: ThemeProgress,
  url: 'v2/user_theme_progress'
});
