'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  url: function () {
    return 'v2/theme/' + encodeURIComponent(this.attributes.id);
  },

  defaults: {
    id: '',
    title: '',
    subtitle: '',
    description: '',
    icon: '',
    active: false
  }
});
