'use strict';

import ContentView from '../views/content';

import App from '../app';

import UserModel from '../models/user';

import _ from 'underscore';
import datepicker from '../utils/datepicker';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import FormMixin from '../utils/formmixin.js';
import DateUtil from '../utils/dateutil';
import I18n from '../utils/i18n';
import Validator from 'validatorjs';

import jQuery from 'jquery';

let ProfileView = ContentView.extend({
  template: 'profile',
  isPublic: true,

  userModel: null,
  edit: false,
  originalEmail: null,
  originalLanguage: null,

  ui: {
    form: 'form.user-form',
    email: '#email',
    birthdate: '#birthdate',
    pdp_participant: '#pdp_participant',
    region: '.user-form [name="region"]',
    language: '#language',
    existing_password: '#existing_password',
    password: '#password',
    password_confirmation: '#password_confirmation',
    privacy_policy_approved: '#privacy_policy_approved',
    contact_activities_events: '#contact_activities_events',
    contact_studies:'#contact_studies',
    submit: 'button[type="submit"]'
  },

  events: {
    'click @ui.submit': 'submit'
  },

  initialize: function () {
    var self = this;
    Session.getProfile().then(function (user) {
      self.userModel = user;
      self.edit = true;
      self.render();
    }, function () {
      self.userModel = null;
      self.edit = false;
    });
  },

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'profile'});

    let view = this;
    window.setTimeout(function () {
      datepicker(view.ui.birthdate, {
        defaultView: 'overlay'
      });
    }, 100);
  },

  getTemplateVarsFromModel: function () {
    let data = {
      edit: Session.isLoggedIn,
      privacyStatementUrl: App.config.get('privacyStatementUrl'),
      email: '',
      birthdate: '',
      research_number: '',
      language: '',
      languages: [],
      region: '',
      regions: [],
      pdp_participant: 0,
      contact_activities_events: 0,
      contact_studies: 0,
    };
    if (this.userModel) {
      data = jQuery.extend({}, data, this.userModel.attributes);
      if (data.birthdate) {
        data.birthdate = DateUtil.htmlDateToLocal(data.birthdate);
      }
    } else {
      data.language = I18n.getLanguage();
    }

    const languages = I18n.getLanguages();
    for (let langCode in languages) {
      data.languages.push({
        value: langCode,
        title: languages[langCode],
        selected: langCode === data.language,
      });
    }

    const regions = App.config.get('regions');
    for (let regionCode in regions) {
      data.regions.push({
        value: regionCode,
        title: regions[regionCode],
        selected: regionCode === data.region,
      });
    }

    return data;
  },

  submit: function (event) {
    event.preventDefault();

    App.isBusy(true);
    let profileView = this;

    // Validate form
    let validation = new Validator(this.getFormValues(this.ui.form), this.getFormValidationRules());
    validation.setAttributeNames(this.getFormValidationAttributeNames());
    if (validation.passes()) {
      // Update model.
      if (!this.edit) {
        this.userModel = new UserModel();
      } else if (this.originalEmail === null) {
        this.originalEmail = this.userModel.get('email');
        this.originalLanguage = this.userModel.get('language');
      }

      this.userModel.set({
        birthdate: DateUtil.localToHtmlDate(this.ui.birthdate.val()),
        gender: this.ui.form.find('[name="gender"]:checked').val(),
        pdp_participant: this.ui.pdp_participant.prop('checked') ? 1 : null,
        region: this.ui.region.val(),
        language: this.ui.form.find('[name="language"]:checked').val(),
        contact_activities_events: this.ui.contact_activities_events.prop('checked') ? 1 : null,
        contact_studies: this.ui.contact_studies.prop('checked') ? 1 : null,
      });

      let saveOptions = {};
      if (!this.edit) {
        this.userModel.set({
          username: this.ui.email.val(),
          email: this.ui.email.val(),
          pass: this.ui.password.val()
        });
      } else {
        saveOptions.patch = true;
        this.userModel.set({
          existing_pass: '',
          pass: ''
        });
        if (this.ui.password.val() || this.originalEmail !== this.ui.email.val()) {
          this.userModel.set({
            email: this.ui.email.val(),
            existing_pass: this.ui.existing_password.val()
          });
          if (this.ui.password.val()) {
            this.userModel.set({
              pass: this.ui.password.val()
            });
          }
        }
      }

      this.userModel.save(undefined, saveOptions)
        .done(function () {
          profileView.originalEmail = null;
          App.languageChanged = profileView.originalLanguage !== profileView.userModel.get('language');
          I18n.setPreferredLanguage(profileView.userModel.get('language'));
          profileView.originalLanguage = null;
          App.clearCache();
          App.isBusy(false);

          if (profileView.edit) {
            App.router.navigate('', { trigger: true });
          } else {
            // Re-login user
            Session.login(profileView.userModel.get('email'), profileView.userModel.get('pass'))
              .then(
                function (loginData) {
                  if (profileView.getOption('sid')) {
                    // Associate LIBRA submission with this user.
                    Session.request('POST', 'webform_associate', {sid: profileView.getOption('sid')})
                      .done(function () {
                        App.router.navigate('#snippet', {trigger: true});
                      })
                      .fail(function () {
                        App.router.navigate('#snippet', {trigger: true});
                      });
                  } else {
                    App.router.navigate('#snippet', {trigger: true});
                  }
                },
                function (httpStatus, error) {
                  Dialogs.alert(I18n.get('error_login_failed'));
                  App.isBusy(false);
                }
              );
          }
        })
        .fail(function (xhr, status, error) {
          App.isBusy(false);
          if (xhr.status === 422 || xhr.status === 400) {
            let response = xhr.responseJSON;
            if (xhr.status === 400) {
              response = {
                message: xhr.responseJSON
              };
            }
            let errors = profileView.getErrorResponseDisplayableErrors(response);
            if (errors !== null) {
              Dialogs.alert(errors);
            } else {
              Dialogs.alert(I18n.get('error_profile_save_failed'));
            }
          } else {
            Dialogs.alert(I18n.get('error_profile_save_failed'));
          }
        });
    } else {
      Dialogs.alert(this.getFormDisplayableErrors(validation.errors));
      App.isBusy(false);
    }
    return false;
  },

  getFormValidationRules: function () {
    let rules = {
      email: 'required|email',
      gender: 'required',
      language: 'required',
      birthdate: 'required',
      region: 'required',
      password: [],
    };

    if (!this.edit) {
      rules.password.push('required');
      rules.privacy_policy_approved = 'required';
    }
    rules.password.push('min:8');
    rules.password.push('confirmed');

    return rules;
  },

  getFormValidationAttributeNames: function () {
    return {
      name: I18n.get('name'),
      email: I18n.get('email'),
      gender: I18n.get('gender'),
      language: I18n.get('language'),
      age: I18n.get('age'),
      region: I18n.get('region'),
      research_number: I18n.get('research_number'),
      password: I18n.get('password'),
      privacy_policy_approved: I18n.get('privacy_consent'),
      contact_activities_events: I18n.get('contact_activities_events_consent'),
      contact_studies: I18n.get('contact_studies_consent'),
    };
  }
});

_.extend(ProfileView.prototype, FormMixin);

export default ProfileView;
