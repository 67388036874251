'use strict';

import imagesLoaded from 'imagesloaded';

import * as Templates from '../templates';

function getMessageContent(content, classes, loading) {
  if (typeof classes === 'undefined') {
    classes = [];
  }

  return Templates['chatui_message']({
    content: content,
    classes: classes,
    loading: (typeof loading !== 'undefined' ? loading : false),
  });
}

function scrollContents(container) {
  let viewContainer = jQuery('.view-container');
  viewContainer.get(0).scrollTop = viewContainer.get(0).scrollHeight;

  if (typeof container !== 'undefined') {
    imagesLoaded(container.get(0), function () {
      scrollContents();
    });
  }
}

export function addMessage(container, content, classes, loading) {
  let html = getMessageContent(content, classes, loading);
  container.append(html);
  scrollContents(container);
}

export function replaceMessage(container, selector, content, classes, loading) {
  let html = getMessageContent(content, classes, loading);
  let message = container.find(selector);
  message.replaceWith(html);
  scrollContents(container);
}

export function removeMessage(container, selector) {
  container.find(selector).remove();
  scrollContents();
}

export function addClientMessage(container, content, classes) {
  if (typeof classes === 'undefined') {
    classes = [];
  }

  classes.push('chat-message--client');
  addMessage(container, content, classes);
}

export function replaceClientMessage(container, selector, content, classes) {
  if (typeof classes === 'undefined') {
    classes = [];
  }

  classes.push('chat-message--client');
  replaceMessage(container, selector, content, classes);
}

export function addLoadingMessage(container) {
  if (container.find('.chat-message--loading').length > 0) {
    // There is already a loading message.
    return;
  }

  let classes = ['chat-message--loading'];
  addMessage(container, '', classes, true);
}

export function replaceLoadingMessage(container, content, classes) {
  let html = getMessageContent(content, classes);

  // If there's a loading message, replace the content of that message, otherwise create a new one.
  let message = container.find('.chat-message--loading');
  if (message.length > 0) {
    message.replaceWith(html);
  } else {
    container.append(html);
  }
  scrollContents(container);
}

export function removeLoadingMessage(container) {
  removeMessage(container, '.chat-message--loading');
}
