'use strict';

import Backbone from 'backbone';

export default Backbone.Model.extend({
  defaults: {
    id: null,
    title: '',
    icon: '',
    score: null,
    category: '',
    number_completed: null,
    bonus_points: null,
    total_count: null,
    active: false
  }
});
