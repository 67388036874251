'use strict';

import ContentView from '../views/content';

import _ from 'underscore';
import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import BackMixin from '../utils/backmixin.js';
import I18n from '../utils/i18n';

import App from '../app';

let ForgotPasswordView = ContentView.extend({
  template: 'forgot_password',
  isPublic: true,

  ui: {
    username: '#username',
    submit: 'button[type="submit"]',
    back: '.back-link'
  },

  events: {
    'click @ui.submit': 'submit',
    'click @ui.back': 'back'
  },

  submit: function (event) {
    event.preventDefault();

    let username = this.ui.username.val();
    if (!username) {
      Dialogs.alert(I18n.get('enter_your_email'));
      return;
    }
    let message = I18n.get('password_request_sent');

    App.isBusy(true);
    Session.request('POST', 'user/lost-password', { mail: username })
      .done(function (response) {
        Dialogs.alert(message);
        App.isBusy(false);
        App.router.navigate('#login', {trigger: true});
      })
      .fail(function (xhr, status, error) {
        App.isBusy(false);
        if (xhr.status !== 200 && xhr.status !== 400) {
          Dialogs.alert(I18n.get('error_cannot_complete_password_request'));
        } else {
          Dialogs.alert(message);
          App.router.navigate('#login', {trigger: true});
        }
      });

    return false;
  },

  onRender: function () {
    App.refreshHeader({showBackButton: true});
  }
});

_.extend(ForgotPasswordView.prototype, BackMixin);

export default ForgotPasswordView;
