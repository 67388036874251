'use strict';

import ContentView from '../views/content';

import App from '../app';
import jQuery from 'jquery';

export default ContentView.extend({
  template: 'short_libra_score',
  isPublic: true,
  kioskTimer: null,

  ui: {
    register: '.register',
  },

  events: {
    'click @ui.register': 'register',
  },

  onAttach: function (options) {
    if (App.kioskMode) {
      this.kioskTimer = window.setTimeout(function () {
        App.router.navigate('#home', {trigger: true});
      }, 120000);
    }
  },

  onDetach: function (options) {
    if (this.kioskTimer) {
      window.clearTimeout(this.kioskTimer);
    }
  },

  onRender: function () {
    App.refreshHeader({showHeader: false});
  },

  onBeforeRender: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVars());
  },

  getTemplateVars: function () {
    let values = {
      scores: [],
      hasImprovements: false,
      hasMaintain: false,
      improvements: [],
      maintain: [],
      kioskMode: App.kioskMode
    };

    const results = this.getOption('results');
    if (typeof results.scores !== 'undefined') {
      for (let i in results.scores) {
        let score = {
          themeNr: parseInt(i, 10) + 1,
          themeClass: results.scores[i].class,
          themeIcon: results.scores[i].icon,
          themeTitle: results.scores[i].title,
          isImprovement: results.scores[i].score === App.SCORE_IMPROVE,
          isMaintain: results.scores[i].score === App.SCORE_MAINTAIN,
        };

        if (score.isImprovement) {
          values.improvements.push({
            title: score.themeTitle,
          });
        }
        else if (score.isMaintain) {
          values.maintain.push({
            title: score.themeTitle,
          });
        }

        values.scores.push(score);
      }
    }

    values.hasImprovements = (values.improvements.length > 0);
    values.improvements = this.setArrayIndexMarkers(values.improvements);
    values.hasMaintain = (values.maintain.length > 0);
    values.maintain = this.setArrayIndexMarkers(values.maintain);

    return values;
  },

  setArrayIndexMarkers: function (arr) {
    return arr.map(function (currentValue, index, array) {
      currentValue.isSecondToLast = (index === array.length - 2);
      currentValue.isNotLast = (index !== array.length - 1);
      if (currentValue.isSecondToLast) {
        currentValue.isNotLast = false;
      }
      return currentValue;
    });
  },

  register: function (event) {
    event.preventDefault();
    let fragment = '#register';
    const results = this.getOption('results');
    console.log(results);
    if (typeof results.sid !== 'undefined') {
      fragment += '/' + results.sid;
    }
    App.router.navigate(fragment, {trigger: true});
    return false;
  }

});
