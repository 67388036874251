'use strict';

import Backbone from 'backbone';

import InfoMenuItemModel from '../models/info_menu_item';
import I18n from '../utils/i18n'

export default Backbone.Collection.extend({
  model: InfoMenuItemModel,
  url: function () {
    // The language is already passed in Session.request(), but we do it here to have the results cached per language.
    return 'v2/content?l=' + I18n.getLanguage();
  }
});
