'use strict';

import ContentView from '../views/content';

import ThemeProgressItemsCollection from '../collections/theme_progress_items';
import NutSubmissionsCollection from '../collections/nut_submissions';

import jQuery from 'jquery';
import DateUtil from '../utils/dateutil';
import I18n from '../utils/i18n';
import App from '../app';

export default ContentView.extend({
  template: 'snippet',
  isPublic: false,

  audioElement: null,
  audioHasPlayed: false,

  themeProgressCollection: null,
  nutSubmissionsCollection: null,

  ui: {
    startSnippetLink: '.noot-link',
    nootFull: '.noot-full',
    themesPageLink: '.themes-page-link',
  },

  events: {
    'click @ui.startSnippetLink': 'startSnippet',
    'click @ui.themesPageLink': 'gotoThemes',
  },

  initialize: function (options) {
    if (App.languageChanged) {
      // Force a reload to update interface texts.
      window.location.reload();
      App.languageChanged = false;
    }

    this.listenTo(this.model, 'sync', this.updateProgress);
    this.model.fetch({cache: true, expires: 60});

    this.themeProgressCollection = new ThemeProgressItemsCollection();
    this.listenTo(this.themeProgressCollection, 'sync', this.updateSubmissions);

    this.nutSubmissionsCollection = new NutSubmissionsCollection();
    this.listenTo(this.nutSubmissionsCollection, 'sync', this.update);

    let view = this;

    if (Modernizr.audio) {
      this.audioElement = document.createElement('audio');
      this.audioElement.setAttribute('src', 'media/walnut.mp3');
      this.audioElement.addEventListener('ended', function() {
        view.audioHasPlayed = true;
        view.ui.nootFull.addClass('has-played');
        view.ui.startSnippetLink.trigger('click');
      });
    }
  },

  onRender: function () {
    App.refreshHeader({activeTab: 'snippet'});
  },

  updateProgress: function () {
    this.themeProgressCollection.fetch({cache: true, expires: 60});
  },

  updateSubmissions: function () {
    const themes = this.getActiveThemes();
    if (themes.length > 0) {
      let themeIds = [];
      for (let i in themes) {
        themeIds.push(themes[i].get('id'));
      }

      this.nutSubmissionsCollection.themeIds = themeIds;
      this.nutSubmissionsCollection.fetch({ cache: false });
    } else {
      this.update();
    }
  },

  update: function () {
    this.templateContext = jQuery.extend({}, this.templateContext, this.getTemplateVarsFromModel());
    this.render();
  },

  startSnippet: function (event) {
    event.preventDefault();

    if (this.audioElement && !this.audioHasPlayed) {
      if (navigator.vibrate) {
        setTimeout(function() {
          navigator.vibrate([150, 200, 50]);
        }, 500);
      }

      this.ui.nootFull.addClass('is-playing');

      this.audioElement.play();
      return false;
    }

    if (this.model.get('subsequent_nut')) {
      App.router.navigate('session/' + this.model.get('subsequent_nut'), {trigger: true});
    } else if (this.model.get('libra_id')) {
      App.router.navigate('session/' + this.model.get('libra_id'), {trigger: true});
    }

    return false;
  },

  gotoThemes: function (event) {
    event.preventDefault();

    App.router.navigate('themes', {trigger: true});

    return false;
  },

  getActiveThemes: function () {
    let themes = [];
    for (let i = 0; i < this.themeProgressCollection.length; i++) {
      let progress = this.themeProgressCollection.at(i);
      if (progress.get('active')) {
        themes.push(progress);
      }
    }
    return themes;
  },

  getTemplateVarsFromModel: function () {
    let now = new Date();
    let data = {
      isLoaded: true,
      day: DateUtil.getDate(now),
      month: DateUtil.getFullMonth(now),
      hasSnippet: false,
      theme: false,
      themeIcon: '',
      nutSubmissions: [],
      bonus: 0,
      bonusString: '',
      firstReward: false,
      completed: false,
      rewardTotalCount: 0,
      rewardCompletedCount: 0,
      nextNutIsLibra: !!this.model.get('libra_id'),
      isDraft: this.model.get('is_draft'),
    };
    if (this.model.get('subsequent_nut') || this.model.get('libra_id')) {
      data.hasSnippet = true;
    } else {
      // Get active themes.
      const themes = this.getActiveThemes();
      for (let i in themes) {
        let theme = themes[i];
        data.rewardCompletedCount += theme.get('number_completed');
        data.rewardTotalCount += theme.get('total_count');
        data.bonus += theme.get('bonus_points');
        data.theme = true;
        data.theme_title = theme.get('title');
      }
      data.firstReward = data.rewardCompletedCount === 1;
      if (this.model.get('completed')) {
        data.completed = true;
      }
      data.nutSubmissions = this.getCompletedNutSubmissions();
    }

    if (data.bonus == 1) {
      data.bonusString = I18n.get('bonus_singular');
    } else {
      data.bonusString = I18n.get('bonus_plural', {count: data.bonus});
    }

    return data;
  },

  getCompletedNutSubmissions: function () {
    let submissions = [];
    for (let i = 0; i < this.nutSubmissionsCollection.length; i++) {
      let session = this.nutSubmissionsCollection.at(i);
      let completedDate = session.get('completed_date');
      submissions.push({
        'id': session.get('id'),
        'title': session.get('title'),
        'completedDate': completedDate ? DateUtil.formatDateFullMonth(completedDate) : '',
        'imageNr': (i % 4) + 1,
      });
    }

    return submissions;
  }
});
