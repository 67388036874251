'use strict';

import ContentView from '../views/content';

import App from '../app';

import Session from '../utils/session';
import * as Dialogs from '../utils/dialogs';
import * as QuestionManagerHelper from '../utils/questionmanagerhelper';
import * as FormHelper from '../utils/formhelper';

import jQuery from 'jquery';
import ShortLibraScoreView from './short_libra_score';
import Backbone from 'backbone';
import I18n from "../utils/i18n";

let CoachQuestionView = ContentView.extend({
  template: 'coach_question',
  isPublic: true,

  ui: {
    prevSubmit: '.controls .btn-prev.form-submit',
    nextSubmit: '.controls .btn-next.form-submit',
    cancel: '.cancel a',
    question: '.question',
    message: '.message',
    answers: '.answers',
    radioButtons: '.answers input[type="radio"]',
    checkBoxes: '.answers input[type="checkbox"]'
  },

  events: {
    'click @ui.prevSubmit': 'previousSubmit',
    'click @ui.nextSubmit': 'nextSubmit',
    'click @ui.cancel': 'cancel',
    'change @ui.radioButtons': 'selectRadioButton',
    'change @ui.checkBoxes': 'selectCheckbox'
  },

  previousSubmit: function (event) {
    event.preventDefault();
    let questionManager = this.getOption('questionManager');

    if (!this.model.get('is_first')) {
      let view = this;
      App.isBusy(true);
      questionManager.getPreviousQuestion()
        .then(
          function (question) {
            if (question === null) {
              // No further questions, test has ended.
              if (!Session.isLoggedIn) {
                App.isBusy(false);
                App.router.navigate('#short-libra-score');
                App.goto(new ShortLibraScoreView({
                  results: questionManager.getResults()
                }));
              } else {
                App.isBusy(false);
                App.router.navigate('#snippet', {trigger: true});
              }
              return;
            }

            App.router.navigate('session/' + view.getOption('sessionId') + '/' + question.get('id'));
            App.goto(new CoachQuestionView({
              model: question,
              questionManager: questionManager,
              sessionId: view.getOption('sessionId'),
              //dailySession: view.getOption('dailySession')
            }), true);
          },
          function () {
            Dialogs.alert(I18n.get('navigation_error'));
            App.isBusy(false);
          }
        );
    } else if (this.model.get('is_completed')) {
      App.router.navigate('snippet', {trigger: true});
    }

    return false;
  },

  nextSubmit: function (event) {
    event.preventDefault();
    let questionManager = this.getOption('questionManager');

    let answer = QuestionManagerHelper.getSubmittedAnswerValue(this.model, this.$el);
    if (!this.model.get('is_completed') && answer.error) {
      Dialogs.alert(answer.error);
      return false;
    }

    let view = this;

    App.isBusy(true);
    questionManager.getNextQuestion(answer)
      .then(
        function (question) {
          if (question === null) {
            // No further questions, test has ended.
            if (!Session.isLoggedIn) {
              App.isBusy(false);
              App.router.navigate('#short-libra-score', {trigger: true});
            } else {
              App.isBusy(false);
              Backbone.fetchCache.clearItem('v2/active_session');
              Backbone.fetchCache.clearItem('v2/user_theme_progress');
              App.router.navigate('#snippet', {trigger: true});
            }
            return;
          }

          App.router.navigate('session/' + view.getOption('sessionId') + '/' + question.get('id'));
          App.goto(new CoachQuestionView({
            model: question,
            questionManager: questionManager,
            sessionId: view.getOption('sessionId'),
          }), false);
        },
        function () {
          Dialogs.alert(I18n.get('error_send_answer'));
          App.isBusy(false);
        }
      );

    return false;
  },

  cancel: function (event) {
    event.preventDefault();

    if (Session.isLoggedIn) {
      App.router.navigate('#snippet', {trigger: true});
    } else {
      App.router.navigate('', {trigger: true});
    }

    return false;
  },

  onBeforeRender: function (options) {
    this.model.updateOptions();
    this.templateContext = jQuery.extend({}, this.templateContext, {
      loggedIn: Session.isLoggedIn,
      isDailySession: true,
    });
  },

  onRender: function (options) {
    if (!Session.isLoggedIn) {
      App.refreshHeader({showHeader: false});
    }

    let checkedRadios = this.ui.radioButtons.filter(':checked');
    if (checkedRadios.length > 0) {
      this.ui.radioButtons.not(checkedRadios).addClass('unselected');
    }

    if (this.model.get('is_first') && !this.model.get('is_completed')) {
      this.ui.prevSubmit.prop('disabled', true);
    } else {
      this.ui.prevSubmit.prop('disabled', false);
    }

    if (this.model.get('required') && !this.model.get('is_completed') && (this.ui.radioButtons.length > 0 || this.ui.checkBoxes.length > 0)) {
      if (checkedRadios.length > 0 || this.ui.checkBoxes.is(':checked')) {
        this.ui.nextSubmit.prop('disabled', false);
      } else {
        this.ui.nextSubmit.prop('disabled', true);
      }
    } else {
      this.ui.nextSubmit.prop('disabled', false);
    }
  },

  onAttach: function () {
    FormHelper.attachElement(this.ui.answers);
    FormHelper.attachElement(this.ui.message);
    FormHelper.attachElement(this.ui.question);
    this.ui.message.fitVids();
  },

  selectRadioButton: function (event) {
    jQuery(':radio[name=' + event.currentTarget.name + ']').addClass('unselected');
    jQuery(event.currentTarget).removeClass('unselected');
    this.ui.nextSubmit.prop('disabled', false);
  },

  selectCheckbox: function (event) {
    if (this.model.get('required')) {
      this.ui.nextSubmit.prop('disabled', !this.ui.checkBoxes.is(':checked'));
    }
  }

});

export default CoachQuestionView;
