'use strict';

import QuestionModel from '../models/question';

import Session from './session';

export default class {

  constructor (sessionId, isCompletedSession) {
    this.sessionId = sessionId;
    this.isCompletedSession = isCompletedSession;
    this.currentQuestionId = 0;
  }

  getFirstQuestion () {
    let self = this;
    return new Promise(function (resolve, reject) {
      self.currentQuestionId = 0;
      self._getQuestion()
        .done(function (data) {
          resolve(new QuestionModel(self._normalizeQuestion(data)));
        })
        .fail(function () {
          reject();
        });
    });
  }

  getPreviousQuestion () {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.currentQuestionId === 0) {
        reject();
      } else {
        self.currentQuestionId--;
        self._getQuestion()
          .done(function (data) {
            resolve(new QuestionModel(self._normalizeQuestion(data)));
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  getNextQuestion (answer) {
    let self = this;
    return new Promise(function (resolve, reject) {
      if (self.isCompletedSession) {
        self.currentQuestionId++;
        self._getQuestion()
          .done(function (data) {
            if (typeof data !== 'undefined') {
              resolve(new QuestionModel(self._normalizeQuestion(data)));
            } else {
              resolve(null);
            }
          })
          .fail(function () {
            reject();
          });
      } else {
        let postAnswer = answer.value;
        if (typeof postAnswer !== 'string') {
          postAnswer = postAnswer.join('|');
        }
        Session.request('POST', self._getQuestionUrl(), {answer: postAnswer})
          .done(function (data) {
            if (data.next_question_id) {
              self.currentQuestionId = data.next_question_id;
              self._getQuestion()
                .done(function (data) {
                  resolve(new QuestionModel(self._normalizeQuestion(data)));
                })
                .fail(function () {
                  reject();
                });
            } else {
              resolve(null);
            }
          })
          .fail(function () {
            reject();
          });
      }
    });
  }

  getResults() {
    return {};
  }

  _getQuestionUrl () {
    return 'v2/nut/' + encodeURIComponent(this.sessionId) + '/question/' + encodeURIComponent(this.currentQuestionId);
  }

  _getQuestion () {
    return Session.request('GET', this._getQuestionUrl());
  }

  _normalizeQuestion (data) {
    data.id = this.currentQuestionId;
    data.is_first = this.currentQuestionId === 0;
    data.is_completed = this.isCompletedSession;
    if (typeof data.type !== 'undefined') {
      for (let i in this.QUESTION_TYPES) {
        if (data.type === this.QUESTION_TYPES[i]) {
          data.type = i;
          break;
        }
      }
    }
    if (data.type === 'multiSelect' && data.answer) {
      data.answer = data.answer.split('|');
    }
    return data;
  }

  get useChatUi () {
    return false;
  }

  /**
   * Question types.
   */
  get QUESTION_TYPES() {
    return {
      'message': 'text_with_embeds',
      'multipleChoice': 'radiobuttons',
      'number': null,
      'slider': 'rangeslider',
      'scale': null,
      'text': 'text_field',
      'date': null,
      'multiSelect': 'checkboxes',
    };
  }

}
