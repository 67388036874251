'use strict';

import Marionette from 'backbone.marionette';

import App from '../app';
import Session from '../utils/session';
import jQuery from "jquery";
import BackMixin from "../utils/backmixin";
import I18n from '../utils/i18n';
import _ from "underscore";

let HeaderView = Marionette.View.extend({
  template: 'header',

  ui: {
    back: '.back-link',
    tabButton: '.tab-button',
    languageTitle: '.dropdown-toggle .title',
    languageLink: '.dropdown-menu a',
  },

  events: {
    'click @ui.back': 'back',
    'click @ui.languageLink': 'changeLanguage',
  },

  headerData: {},

  onBeforeRender: function (options) {
    this.templateContext = jQuery.extend({}, this.templateContext, {
      showHeader: true,
      activeTab: '',
      loggedIn: Session.isLoggedIn,
      showBackButton: false,
      showLoginButton: !App.kioskMode,
      languages: I18n.getLanguages(),
    }, this.headerData);

    if (this.templateContext.showHeader) {
      jQuery('#content').removeClass('full-height');
    } else {
      jQuery('#content').addClass('full-height');
    }
  },

  onRender: function () {
    this.updateLanguage(I18n.getLanguage());
    let subPage = this.templateContext.activeTab;
    // Select active item
    this.ui.tabButton.each(function () {
      if (subPage === jQuery(this).data('subpage')) {
        jQuery(this).parent().addClass('is-active');
      } else {
        jQuery(this).parent().removeClass('is-active');
      }
    });
  },

  onHeaderRefresh: function (data) {
    this.headerData = data;
    this.render();
  },

  updateLanguage: function (code) {
    this.ui.languageTitle.text(code.toUpperCase());
  },

  changeLanguage: function (event) {
    event.preventDefault();
    let language = jQuery(event.currentTarget).data('language');
    this.updateLanguage(language);
    I18n.setLanguage(language);
    I18n.setPreferredLanguage(language);
    window.location.reload();
  }

});

_.extend(HeaderView.prototype, BackMixin);

export default HeaderView;
