'use strict';

import datepicker from 'js-datepicker';
import dateFormat from 'dateformat';
import jQuery from 'jquery';

import I18n from './i18n';

function getInitialValue (element) {
  let reg = element.val().match(/^([0-9]{2})-([0-9]{2})-([0-9]{4})$/);
  if (reg) {
    let date = new Date();
    date.setFullYear(parseInt(reg[3], 10));
    date.setMonth(parseInt(reg[2], 10) - 1);
    date.setDate(parseInt(reg[1]));
    return date;
  }
  return null;
}

export default function (element, options) {
  if (element.data('datepicker-initialized')) {
    return;
  }

  options = options || {};
  let defaultOptions = {};

  defaultOptions.formatter = function (input, date, instance) {
    input.value = dateFormat(date, 'dd-mm-yyyy');
  };

  defaultOptions.startDay = 1;
  defaultOptions.customDays = [
    I18n.get('day_sun'),
    I18n.get('day_mon'),
    I18n.get('day_tue'),
    I18n.get('day_wed'),
    I18n.get('day_thu'),
    I18n.get('day_fri'),
    I18n.get('day_sat'),
  ];
  defaultOptions.customMonths = [
    I18n.get('month_full_jan'),
    I18n.get('month_full_feb'),
    I18n.get('month_full_mar'),
    I18n.get('month_full_apr'),
    I18n.get('month_full_may'),
    I18n.get('month_full_jun'),
    I18n.get('month_full_jul'),
    I18n.get('month_full_aug'),
    I18n.get('month_full_sep'),
    I18n.get('month_full_oct'),
    I18n.get('month_full_nov'),
    I18n.get('month_full_dec'),
  ];
  defaultOptions.customOverlayMonths = [
    I18n.get('month_jan'),
    I18n.get('month_feb'),
    I18n.get('month_mar'),
    I18n.get('month_apr'),
    I18n.get('month_may'),
    I18n.get('month_jun'),
    I18n.get('month_jul'),
    I18n.get('month_aug'),
    I18n.get('month_sep'),
    I18n.get('month_oct'),
    I18n.get('month_nov'),
    I18n.get('month_dec'),
  ];
  defaultOptions.overlayButton = I18n.get('datepicker_overlay_button');
  defaultOptions.overlayPlaceholder = I18n.get('datepicker_overlay_placeholder');
  defaultOptions.dateSelected = getInitialValue(element);

  options = jQuery.extend({}, defaultOptions, options);
  element.data('datepicker-initialized', true);
  return datepicker(element.get(0), options);
};
